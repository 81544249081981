export enum BookingAppointment {
  formInfo= 'Form_Info',
  inviteCode= 'Invite_code',
  cancelled= 'Cancelled',
  queueInviteCode= 'queue_invite_code'
}

export const OptimyScreenBookingParams: string[] = ['editbooking','cancelbooking'];

export const BookingConfigRegularFields: string[] = ['full_name','phone','email', 'schoolName'];

export enum MaxCharLength {
  maxCharactersLimit = 1500
}

export interface TimeZoneInfo {
  userTimeZone: string;
  zone: string;
}

export enum StartEndTime {
    endTime ='23:59',
    startTime = '00:00'
}

export enum ClassesAndAttributes {
  disabledClass = 'btn-light text-muted outside',
  dataStatusAttribute = 'date-status'
}

export const WeekDays: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat','Sun'];

export const TimeSlots: string[] = ['09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30',
  '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45']

export enum StaticData {
  time = 'XX:XX',
  date = 'Wkd, Mnn DD, YYYY',
  dateFormat = 'YYYY-MM-DD'
}

export interface DateStructFormat {
  year: number| string;
  month: number| string;
  day: number | string;
}

export interface ScheduledInfoCreate {
    comment: string | null;
    created: number;
    duration: number;
    email: string;
    end_dt_tz: string;
    estimated_start: number;
    guest_link: string;
    guest_modify_link: string;
    guest_cancel_link: string;
    guest_user_id: string;
    id: number;
    lang: string;
    full_name: string;
    phone: string;
    position_index: number;
    postal_code: string;
    queue_id: number;
    queue_position_id: number;
    room_name: string;
    sequence: string;
    start_dt: string;
    start_dt_tz: string;
    uuid: string;
    corporate?: boolean;
    account_name?: null | string;
    account_number?: null | number;
    products_services?: any;
    queue_name?: string;
    segment?: any;
    status?: string;
    city?: string;
    ics_file: string;
  }

  export interface SubmitBookingSession {
    tenantid: number;
    full_name: string;
    email: string;
    phone?: number | string;
    ip?: string | any;
    user_agent: any;
    queue_id: number;
    attr: string;
    client_user_id?: string;
  }

  export interface BookingAttributes{
    start_dt: string | any;
    duration: number;
    comment?: string;
    lang: string;
    guest_tz: string;
    guest_hostname: string;
    account_name?: string;
    city?: string;
    postal_code?: string;
  }
  export interface AllInputFields {
    name: string;
    label?: string;
  }

  export interface BookingFormInfoEvent {
    form: any;
    formFields: AllInputFields[];
  }

  export enum Keys {
    sessionInfo = 'sessionInfo'
  }

  export const FormControlNames: string[] = ['full_name', 'email', 'phone', 'comment']

  export interface SchoolName {
    schoolName: string;
    city: string;
  }

  export enum McCarthyName {
    school = 'School'
  }
export const McCarthySchoolName: SchoolName[] =[
        {
            'schoolName': 'E.S.C. Notre-Dame-du-Sault',
            'city': 'Sault Ste. Marie'
        },
        {
            'schoolName': 'Holy Cross C.S.S. ',
            'city': 'Kingston'
        },
        {
            'schoolName': 'Holy Trinity C.S.S.',
            'city': 'Cornwall'
        },
        {
            'schoolName': 'Holy Trinity Catholic High School',
            'city': 'Simcoe'
        },
        {
            'schoolName': 'Nicholson Catholic College',
            'city': 'Belleville'
        },
        {
            'schoolName': 'Notre Dame C.H.S.',
            'city': 'Carleton Place'
        },
        {
            'schoolName': 'Regiopolis Notre Dame C.H.S.',
            'city': 'Kingston'
        },
        {
            'schoolName': "St. Anne's Catholic S.S",
            'city': 'Clinton'
        },
        {
            'schoolName': 'St. Francis Xavier C.H.S.',
            'city': 'Hammond'
        },
        {
            'schoolName': 'St. John Catholic High School',
            'city': 'Perth'
        },
        {
            'schoolName': "St. Joseph's C.S.S.",
            'city': 'Cornwall'
        },
        {
            'schoolName': 'St. Mary Catholic High School',
            'city': 'Brockville'
        },
        {
            'schoolName': 'St. Michael Catholic High School',
            'city': 'Kemptville'
        },
        {
            'schoolName': 'St. Michael Catholic Secondary School',
            'city': 'Stratford'
        },
        {
            'schoolName': "St. Paul's C.S.S",
            'city': 'Trenton'
        },
        {
            'schoolName': 'St. Theresa C.S.S',
            'city': 'Belleville'
        },
        {
            'schoolName': 'St. Thomas Aquinas Catholic High School',
            'city': 'Russell'
        },
        {
            'schoolName': 'Assumption College School',
            'city': 'Brantford'
        },
        {
            'schoolName': 'St. Joseph-Scollard Hall C.S.S.',
            'city': 'North Bay'
        },
        {
            'schoolName': "St. John's College (Brantford)",
            'city': 'Brantford'
        },
        {
            'schoolName': 'E.S.C. Algonquin',
            'city': 'North Bay'
        },
        {
            'schoolName': 'Ursuline College S.S.',
            'city': 'Chatham'
        },
        {
            'schoolName': "St. Patrick's Catholic High School",
            'city': 'Sarnia'
        },
        {
            'schoolName': 'Other school not listed',
            'city': ''
        }
  ]

export const QueryParamList = ['code', 'optimy_code', 'optimy_screen', 'resumeCall', 'resumeWaiting', 'callCompleted'];
