import { NgxSpinnerModule } from 'ngx-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DoBootstrap, Injector, NgModule, SecurityContext } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { IMaskModule } from 'angular-imask';
import { AngularSvgIconModule, SvgIconRegistryService } from 'angular-svg-icon';
import { TagInputModule } from 'ngx-chips';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgentProfileComponent } from './components/agent-profile/agent-profile.component';
import { CallInFutureComponent } from './components/call-in-future/call-in-future.component';
import { CallStatusScreenComponent } from './components/call-status-screen/call-status-screen.component';
import { CloseRouteComponent } from './components/close-route/close-route.component';
import { FooterComponent } from './components/footer/footer.component';
import { MessageUsComponent } from './components/message-us/message-us.component';
import { OptimyLauncherComponent } from './components/optimy-launcher/optimy-launcher.component';
import { UserFeedbackComponent } from './components/user-feedback/user-feedback.component';
import { UserInfoFormComponent } from './components/user-info-form/user-info-form.component';
import { ChatComponent } from './components/video-call/chat/chat.component';
import { ConciergeComponent } from './components/video-call/chat/concierge/concierge.component';
import { CobrowseAlertComponent } from './components/video-call/in-call/cobrowse-alert/cobrowse-alert.component';
import { ControlButtonComponent } from './components/video-call/in-call/controls/control-button/control-button.component';
import { ControlsComponent } from './components/video-call/in-call/controls/controls.component';
import { InCallComponent } from './components/video-call/in-call/in-call.component';
import { MenuComponent } from './components/video-call/in-call/menu/menu.component';
import { InviteGuestComponent } from './components/video-call/invite-guest/invite-guest.component';
import { LeaveMessageModalComponent } from './components/video-call/leave-message-modal/leave-message-modal.component';
import { PermissionPopupComponent } from './components/video-call/permission-popup/permission-popup.component';
import { SettingsModalComponent } from './components/video-call/settings-modal/settings-modal.component';
import { VideoCallComponent } from './components/video-call/video-call.component';
import {
  Constants
} from './core/constants/constant';
import { svgIcons } from './core/constants/icons';
import { BlockDoubleClickDirective } from './core/directives/block-double-click/block-double-click.directive';
import { MarkedPipe } from './core/pipe/marked/marked.pipe';
import { BookingModule } from './modules/booking/booking.module';
import { SharedModule } from './modules/shared/shared.module';
import { MetaReducer, StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { metaReducers } from './store/app.reducers';
import { reducers, State } from './store/app.states';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import createNgrxMiddleware from 'logrocket-ngrx';
import * as LogRocket from 'logrocket'
import { LinkyModule } from 'angular-linky';
import { HorizontalControlsComponent } from './components/video-call/in-call/horizontal-controls/horizontal-controls.component';
import { NudgeTextComponent } from './components/nudge-text/nudge-text.component';
import { ConfirmationPanelComponent } from './components/confirmation-panel/confirmation-panel.component';
import { ScheduledCallsInfoScreenComponent } from './components/scheduled-calls-info-screen/scheduled-calls-info-screen.component';

const logrocketMiddleware = createNgrxMiddleware(LogRocket, {
  actionSanitizer: function (action) {
    return {
      ...action
    };
  }
});

export function getMetaReducers(): MetaReducer<State>[] {
  return metaReducers.concat([logrocketMiddleware]);
}

const components = [
  AgentProfileComponent,
  AppComponent,
  BlockDoubleClickDirective,
  CallInFutureComponent,
  CallStatusScreenComponent,
  ChatComponent,
  CloseRouteComponent,
  CobrowseAlertComponent,
  ConciergeComponent,
  ControlButtonComponent,
  ControlsComponent,
  FooterComponent,
  InCallComponent,
  InviteGuestComponent,
  LeaveMessageModalComponent,
  MarkedPipe,
  MenuComponent,
  MessageUsComponent,
  OptimyLauncherComponent,
  PermissionPopupComponent,
  SettingsModalComponent,
  UserFeedbackComponent,
  UserInfoFormComponent,
  VideoCallComponent,
  HorizontalControlsComponent,
  NudgeTextComponent,
    ConfirmationPanelComponent,
    ScheduledCallsInfoScreenComponent


];

// const devComponents = [
//   ButtonsStatesComponent,
//   SandboxComponent,
// ];

@NgModule({
    declarations: [
        ...components,
        // ...[environment.local ? devComponents : []]
    ],
    imports: [
        BrowserModule,
        SharedModule,
        BrowserAnimationsModule,
        DragDropModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        ReactiveFormsModule,
        FormsModule,
        TagInputModule,
        TranslateModule.forRoot(),
        ToastrModule.forRoot(),
        AngularSvgIconModule.forRoot(),
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({
            maxAge: 50, // Retains last 25 states
        }),
        NgxSpinnerModule,
        IMaskModule,
        BookingModule,
        LinkyModule
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: Constants.licenseKey, useValue: (window as any).OPTIMY_LICENSE_KEY },
        { provide: 'googleTagManagerId', useValue: environment.gaKey },
        { provide: Constants.pluginConfig, useValue: (window as any).OPTIMY_CONFIG },
        {
            provide: USER_PROVIDED_META_REDUCERS,
            useFactory: getMetaReducers
        }
    ]
})
export class AppModule implements DoBootstrap {
  constructor(
    private svgRegistry: SvgIconRegistryService,
    private injector: Injector,
    private domSanitizer: DomSanitizer) {
    this.registerSvgIcons();
  }
  ngDoBootstrap() {
    const externalTileCE = createCustomElement(OptimyLauncherComponent, { injector: this.injector });
    customElements.define('optimy-launcher', externalTileCE);
  }

  private registerSvgIcons(): void {
    const pluginVersion = Constants.pluginVersion;
    svgIcons.forEach((icon) => {
      const trustedResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`${icon.iconPath}?v=${pluginVersion}`);
      const trustedUrl = this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, trustedResourceUrl) as string;
      this.svgRegistry.loadSvg(trustedUrl, icon.iconName);
    });
  }
}
