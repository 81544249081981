import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import {
  CallStates,
  DeviceList,
  DeviceStatus,
  DeviceTypes, IgnoreDeviceIdTypes,
  PermissionError
} from '../../../../core/constants/call.modal';
import {
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels, SnowplowTrackerProperties
} from '../../../../core/constants/trackerLabels';
import { Menu } from '../menu/menu';
import { Subject } from 'rxjs';
import { ChatService } from '../../../../core/services/chat/chat.service';
import { UtilsService } from '../../../../core/services/utils/utils.service';
import { ResizeService } from '../../../../core/services/resize/resize.service';
import { SharedService } from '../../../../core/services/shared/shared.service';
import { SnowplowService } from '../../../../core/services/snowplow/snowplow.service';
import { ScreenOrientationService } from '../../../../core/services/utils/screen-orientation.service';
import { takeUntil } from 'rxjs/operators';
import { PluginStates, ResizeState, RoomInfoStates } from '../../../../core/constants/common.enum';

@Component({
  selector: 'app-horizontal-controls',
  templateUrl: './horizontal-controls.component.html',
  styleUrls: ['./horizontal-controls.component.scss']
})
export class HorizontalControlsComponent implements OnInit {
  videoInputs: DeviceList[] = [];
  camDisable = true;
  micDisable = false;
  switchCamDisable = true;
  showCamError = false;
  showMicError = false;

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    action: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties
  };

  menuOpen = false;

  @Input() public menuList: Menu[];

  @Input() public isMicOn: boolean;

  @Input() public isOutboundCallOrMinimize: boolean;

  @Input() public isCameraOn: boolean;

  @Input() public unreadMessageCount: number;

  // TODO: need to fix the chat option later.
  @Input() public showNotification: boolean;

  @Input() deviceList: MediaDeviceInfo[];

  @Input() isUserInQueue: boolean

  @Output() public toggleMenuEvent = new EventEmitter<{ isMenuOpen: boolean, isInternalCommand: boolean }>();
  @Output() public toggleMicrophoneEvent = new EventEmitter<boolean>();
  @Output() public toggleCameraEvent = new EventEmitter<boolean>();
  @Output() public toggleFullScreenEvent = new EventEmitter<boolean>();
  @Output() public switchCameraEvent = new EventEmitter();
  @Output() public inviteEvent = new EventEmitter();
  @Output() public settingsEvent = new EventEmitter();

  destroy$: Subject<boolean> = new Subject<boolean>();

  currentCategory: string;
  maximize: boolean;

  constructor(
      private cdr: ChangeDetectorRef,
      private chatService: ChatService,
      private zone: NgZone,
      private utils: UtilsService,
      private resizeService: ResizeService,
      private sharedService: SharedService,
      private snowplowService: SnowplowService,
      private screenOrientationService: ScreenOrientationService
  ) {
    this.sharedService.enableDevices$
        .pipe(takeUntil(this.destroy$))
        .subscribe((deviceInfo) => {
          setTimeout(() => {
            this.updateDeviceStatus(deviceInfo);
          }, 500);
        });
    this.sharedService.showPermissionError$
        .pipe(takeUntil(this.destroy$))
        .subscribe((permissionError: PermissionError) => {
          this.showCamError = permissionError.camError;
          this.showMicError = permissionError.micError;
        });
    this.resizeService.resizeSub.pipe(takeUntil(this.destroy$)).subscribe((resp: ResizeState) => {
      this.zone.run(() => {
        this.maximize = resp.isMaximized;
      })
    });
  }


  ngOnInit(): void {
    this.screenOrientationService.orientationChanged$.pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.updateCurrentCategoryValue();
        });
    this.updateCurrentCategoryValue();
    const lastCallState = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.lastCallState);
    this.maximize = lastCallState === CallStates.maximizeFull || lastCallState === CallStates.maximize;
  }

  private updateCurrentCategoryValue(): void {
    this.currentCategory = this.chatService.updateCurrentCategoryValue(this.isUserInQueue, this.maximize);
  }


  ngOnChanges() {
    this.getVideoDeviceList();
  }

  public toggleMicrophone(isMicOn: boolean): void {
    if (!this.micDisable) {
      this.micDisable = true;
      this.isMicOn = isMicOn;
      this.toggleMicrophoneEvent.emit(isMicOn);
    }
  }

  public toggleInvite(): void {
    this.toggleMenu();
    this.inviteEvent.emit();
  }

  public toggleSettings(): void {
    this.toggleMenu();
    this.settingsEvent.emit();
  }

  updateDeviceStatus(deviceInfo: DeviceStatus) {
    if (deviceInfo.cameraUpdated) {
      this.camDisable = false;
    }
    if (deviceInfo.micUpdated) {
      this.micDisable = false;
    }
    if (deviceInfo.switchCamUpdated) {
      this.switchCamDisable = false;
    }
  }

  public toggleFullScreen(): void {
    // this.toggleMenu();
    this.toggleFullScreenEvent.emit(true);
  }

  public toggleCamera(isCameraOn: boolean): void {
    if (!this.camDisable) {
      this.camDisable = true;
      this.isCameraOn = isCameraOn;
      this.toggleCameraEvent.emit(isCameraOn);
      if (this.isCameraOn) {
        this.getVideoDeviceList();
      }
    }
  }


  public toggleMenu(isInternalCommand: boolean = false): void {
    const lastCallState = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.lastCallState);
    this.maximize = lastCallState === CallStates.maximizeFull || lastCallState === CallStates.maximize;
    this.menuOpen = !this.menuOpen;
    this.toggleMenuEvent.emit({ isMenuOpen: this.menuOpen, isInternalCommand });
  }

  closeMediaPopover(type: string, event: Event) {
    event.stopPropagation();
    if (type === 'cam') {
      this.showCamError = false;
    } else {
      this.showMicError = false;
    }
    this.cdr.detectChanges();
  }

  getVideoDeviceList() {
    this.videoInputs = this.deviceList?.filter(device => device.kind === DeviceTypes.videoInput &&
        device.deviceId !== IgnoreDeviceIdTypes.default &&
        device.deviceId !== IgnoreDeviceIdTypes.communications && !device.label.includes(' IR '));
  }

  onClickOfSwitchCamera() {
    if (!this.switchCamDisable) {
      let currentDeviceSelected;
      this.switchCamDisable = true;
      const deviceInfo = this.utils.getLocalVal(PluginStates.deviceInfo);
      const videoDeviceId = deviceInfo?.videoInDevice || this.videoInputs[0]?.deviceId;
      const index = this.videoInputs.findIndex(item => item.deviceId === videoDeviceId);
      if ((index + 1) !== this.videoInputs?.length) {
        currentDeviceSelected = this.videoInputs[index + 1]?.deviceId;
        this.switchCameraEvent.emit(this.videoInputs[index + 1]?.deviceId);
      } else {
        currentDeviceSelected = this.videoInputs[0]?.deviceId;
        this.switchCameraEvent.emit(this.videoInputs[0]?.deviceId);
      }
      this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.click,
          this.spTracker.labels.switchCamera, currentDeviceSelected);
    }
  }

  checkSwitchCameraIconStatus() {
    return this.videoInputs?.length <= 1 ||
        !this.isCameraOn;
  }

}
