<div class="user-feedback op-d-flex op-flex-column" *ngIf="!isSubmitted">
    <div class="op-d-flex op-flex-column">
      <div class="op-d-flex op-flex-row feedback-text op-mb-3">
        <span *ngIf="agentName" class="feedback-info op-mr-2"
          [innerHTML]="'userFeedback.liveSessionEndedText' | translate: {agentName}"></span>
        <span *ngIf="!agentName" class="feedback-info op-mr-2"
          [innerHTML]="'userFeedback.liveSessionEndedTextNoAgentInfo' | translate"></span>
      </div>
      <span class="rate-text" [innerHTML]="'userFeedback.rateText' | translate"></span>
    </div>
    <div class="op-d-flex op-justify-content-center op-mb-4"><i class="avatar op-common-icon op-mt-3"></i></div>
    <div class="op-d-flex op-justify-content-center op-mb-2">
      <div class="star pointer op-mr-3" [ngClass]="{'active': i+1 <= rating || i+1<= hoverRating}"
        (click)="enableStar(i+1, $event)" (mouseover)="starHover(i+1, $event)" (mouseout)="startLeave(i+1, $event)"
        *ngFor="let count of counter(5); let i = index;"></div>
    </div>
    <div>
      <textarea aria-label="specifyRequest" class="user-info op-mt-3"
        [placeholder]="'userFeedback.improveQuestion' | translate" (ngModelChange)="getComments($event)"
        [(ngModel)]="characterCount" maxlength="1000" type="text" rows="3" cols="50"></textarea>
      <div class="op-d-flex op-justify-content-end remaining-char"
        [innerHTML]="'bookingAppointment.charactersLimit' | translate : {characterCount: characterCount?.length, maxCharactersLimit: maxCharactersLimit }">
      </div>
    </div>
    <div class="op-d-flex op-flex-column op-mt-4 op-mb-4">
      <button class="op-btn op-btn-primary op-btn-flat op-mb-3 op-flex-1" appAddSnowplowEvent
        [category]="spTrackerLabels.callRating" [action]="spTrackerLabels.send" [label]=" spTrackerLabels.rate"
        (click)="onSubmitForm()" [disabled]="!rating" [ngClass]="[rating ? 'submit' : '']"
        [innerHTML]="'userFeedback.sendFeedback' | translate"></button>
      <button class="op-btn op-btn-basic op-btn-danger op-flex-1" appAddSnowplowEvent
        [category]="spTrackerLabels.callRating" [action]="spTrackerLabels.skip" [label]=" spTrackerLabels.rate"
        (click)="skip()">{{ 'userFeedback.skitAndExit' | translate}}</button>
    </div>
  </div>
