import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ScreenOrientationService } from '../../core/services/utils/screen-orientation.service';
import { PluginStates } from '../../core/constants/common.enum';
import { UtilsService } from '../../core/services/utils/utils.service';


enum ScreenType {
  confirmationPanel = 'confirmationPanel',
  audioSetupPanel = 'audioSetupPanel',
  audioPermissionPanel = 'audioPermissionPanel'
}

const imageCdnLink = 'https://optimy-cdn-devfront.s3.ca-central-1.amazonaws.com/assets';

const BrowserList = {
  macSafari : `${imageCdnLink}/images/Mac_Safari_SideImage_Arrow.png`,
  macFirefox : `${imageCdnLink}/images/Mac_Firefox_SideImage_Arrow.png`,
  macChrome : `${imageCdnLink}/images/Mac_Chrome_Edge_SideImage_Arrow.png`,
  windowsChrome : `${imageCdnLink}/images/Windows_Chrome_Edge_SideImage_Arrow.png`,
  windowsFirefox : `${imageCdnLink}/images/Windows_Firefox_SideImage_Arrow.png`,
  macSafariBox : `${imageCdnLink}/images/Mac-Safari-Mic-Dialogue.png`,
  macFirefoxBox : `${imageCdnLink}/images/Mac-Firefox-Mic-Dialogue.png`,
  macChromeBox : `${imageCdnLink}/images/Mac-Chrome-Edge-Mic-Dialogue.png`,
  windowsChromeBox : `${imageCdnLink}/images/Windows-Chrome-Edge-Mic-Dialogue.png`,
  windowsFirefoxBox : `${imageCdnLink}/images/Windows-Firefox-Mic-Dialogue.png`,
  macSafariFR : `${imageCdnLink}/images/Mac_Safari_SideImage_FR.png`,
  macFirefoxFR : `${imageCdnLink}/images/Mac_Firefox_SideImage_FR.png`,
  macChromeFR : `${imageCdnLink}/images/Mac_Chrome_Edge_SideImage_FR.png`,
  windowsChromeFR : `${imageCdnLink}/images/Windows_Chrome_Edge_SideImage_FR.png`,
  windowsFirefoxFR : `${imageCdnLink}/images/Windows_Firefox_SideImage_FR.png`,
  macSafariBoxFR : `${imageCdnLink}/images/Mac_Safari_DialogueImage_FR.png`,
  macFirefoxBoxFR : `${imageCdnLink}/images/Mac_Firefox_DialogueImage_FR.png`,
  macChromeBoxFR : `${imageCdnLink}/images/Mac_Chrome_Edge_DialogueImage_FR.png`,
  windowsChromeBoxFR : `${imageCdnLink}/images/Windows_Chrome_Edge_DialogueImage_FR.png`,
  windowsFirefoxBoxFR : `${imageCdnLink}/images/Windows_Firefox_DialogueImage_FR.png`
}
enum OsType {
  mac= 'Macintosh',
  windows = 'Windows'
}

enum BrowserType {
  safari = 'Safari',
  firefox = 'Firefox',
  chrome = 'Chrome'
}

@Component({
  selector: 'app-confirmation-panel',
  templateUrl: './confirmation-panel.component.html',
  styleUrls: ['./confirmation-panel.component.scss']
})

export class ConfirmationPanelComponent implements OnInit {
  @Input() showConfirmationPanel =  false;
  @Input() language =  'en';
  @Output() emitNavigateUser = new EventEmitter();

  confirmationPanelImg = `${imageCdnLink}/images/confirmation-placeholder.png`;
  confirmationPanelMobImg = `${imageCdnLink}/images/confirmation-placeholder-mob.jpeg`;
  confirmationPanelLogo = `${imageCdnLink}/logos/DeLonghi logo.png`;
  confirmationNoVideoImg = `${imageCdnLink}/images/no_video.png`;
  audioSetupPanelImg = `${imageCdnLink}/images/mic-desktop-placeholder.png`;
  audioSetupPanelMobImg = `${imageCdnLink}/images/mic-mobile-placeholder.jpeg`;
  faqUrl : string;
  isLandscape = this.screenOrientationService.isLandscapeAndMobile;
  isMobile = this.screenOrientationService.isOnMobile();
  screenType = ScreenType;

  currentScreen = this.screenType.confirmationPanel;
  audioPermissionImg: string;
  audioPermissionBoxImg: string;

  constructor(private screenOrientationService: ScreenOrientationService,
              private utils: UtilsService) { }

  ngOnInit(): void {
    this.faqUrl = this.language === 'fr' ? 'https://www.delonghi.com/fr-ca/faqs' : 'https://www.delonghi.com/en-ca/faqs';
    this.audioPermissionImg = this.findDeviceType(true);
    this.audioPermissionBoxImg = this.findDeviceType(false);
  }

  findDeviceType(isSide: boolean): string {
    const userAgent = navigator.userAgent;
    if (userAgent.includes(OsType.mac) && userAgent.includes(BrowserType.firefox)) {
      return isSide ? (this.language === 'fr' ? BrowserList.macFirefoxFR :  BrowserList.macFirefox ) :
          (this.language === 'fr' ? BrowserList.macFirefoxBoxFR :  BrowserList.macFirefoxBox);
    } else if (userAgent.includes(OsType.mac) && (!userAgent.includes(BrowserType.chrome) && userAgent.includes(BrowserType.safari))) {
      return isSide ? (this.language === 'fr' ? BrowserList.macSafariFR : BrowserList.macSafari) :
          (this.language === 'fr' ? BrowserList.macSafariBoxFR : BrowserList.macSafariBox);
    } else if (userAgent.includes(OsType.mac) && (userAgent.includes(BrowserType.chrome) && userAgent.includes(BrowserType.safari))) {
      return isSide ? (this.language === 'fr' ? BrowserList.macChromeFR :  BrowserList.macChrome) :
          (this.language === 'fr' ? BrowserList.macChromeBoxFR : BrowserList.macChromeBox);
    } else if (userAgent.includes(OsType.windows) && userAgent.includes(BrowserType.firefox)) {
      return isSide ? (this.language === 'fr' ? BrowserList.windowsFirefoxFR : BrowserList.windowsFirefox) :
          (this.language === 'fr' ? BrowserList.windowsFirefoxBoxFR : BrowserList.windowsFirefoxBox);
    } else if (userAgent.includes(OsType.mac) && userAgent.includes(BrowserType.chrome)) {
      return isSide ? (this.language === 'fr' ? BrowserList.windowsChromeFR : BrowserList.windowsChrome) :
          (this.language === 'fr' ? BrowserList.windowsChromeBoxFR : BrowserList.windowsChromeBox);
    } else {
      return isSide ? (this.language === 'fr' ? BrowserList.windowsChromeFR : BrowserList.windowsChrome) :
          (this.language === 'fr' ? BrowserList.windowsChromeBoxFR : BrowserList.windowsChromeBox);
    }
  }

  checkPermissionAndJoin() {
    const isAllowed = this.utils.getLocalVal(PluginStates.audioPermission);
    if (isAllowed) {
      this.emitNavigateUser.emit(true);
    } else {
      this.currentScreen = this.screenType.audioSetupPanel;
    }
  }

  checkPermission() {
    let alreadyDone = false;
    navigator.mediaDevices.getUserMedia({ audio: true }).then(res=> {
      alreadyDone = true;
      this.utils.setLocalVal(null, [PluginStates.audioPermission], ['allow']);
      this.emitNavigateUser.emit(true);
    }).catch(e=> {
      alreadyDone = true;
      this.utils.setLocalVal(null, [PluginStates.audioPermission], ['rejected']);
      this.emitNavigateUser.emit(true);
    })
    if(!this.isMobile && !alreadyDone) {
      setTimeout(()=> {
        this.currentScreen = this.screenType.audioPermissionPanel;
      }, 50)
    }
  }
  @HostListener('window:resize')
  appHeight() {
    this.isLandscape = this.screenOrientationService.isLandscapeAndMobile;
    this.isMobile = this.screenOrientationService.isOnMobile();
  }

}
