import { ImagePaths } from './icons';
import { SnowplowTrackerLabels } from './trackerLabels';

export const EndPoints = {
    addUserToQueue: 'api/v1/chat/save/queue/position/add',
    cartUpdate: 'api/v1/chat/save/cart/update',
    checkQueue: 'api/v1/chat/save/queue/position/read',
    queuePositionUpdate: 'api/v1/chat/save/queue/position/update',
    createChannel: 'createChannel',
    createGuest: 'api/v1/chat/guest/create',
    createMember: 'addMembers',
    createMessage: 'createMessage',
    typing: 'typing',
    feedbackCreate: 'api/v1/feedback/create',
    getAgentStatus: 'api/v1/chat/agent/status',
    getChantMessage: 'retrieveMessageNew',
    getChantMessageWithToken: 'retrieveMessageNew?pageToken=',
    getLatestAgentStatusInfo: 'agent/status',
    getRoomFromInvite: 'api/v1/chat/save/invite/read',
    getToken: 'token',
    getMembers: 'getMembers',
    getUnconsumedMessageIndex: 'getUnconsumedMessageIndex',
    guestStatusUpdate: 'api/v1/chat/save/guest/status/update',
    memberUpdated: 'memberUpdated',
    ominiChannelSend: 'api/v1/chat/save/omnichannel/guest/send',
    outboundAccept: 'api/v1/chat/save/outbound/accept',
    outboundReject: 'outboundRejected',
    pingGuest: 'api/v1/ping/guest',
    readBookingConfig: 'api/v1/chat/save/config/booking/read',
    sendInvite: 'api/v1/chat/save/invite/send',
    submitBookingInfo: 'api/v1/chat/save/schedule/create',
    updateMember: 'updateMember?identity=',
    updateMessage: 'updateMessage',
    updateMemberIndex: 'updateMemberChatIndex',
    updateLastConsumedMessageIndex: 'updateLastConsumedMessageIndex',
    updateScheduleInfo: 'api/v1/chat/save/schedule/update',
    videoStatus: 'videoStatus',
    ipAddress: 'ipAddress'
};

export const Constants = {
    licenseKey: 'licenseKey',
    apiPaths: EndPoints,
    imagePaths: ImagePaths,
    pluginVersion: 'v2.14',
    optimyUrl: 'https://optimy.ai/',
    pluginConfig: 'pluginConfig',
    optimyLocalStore: 'optimyLocalStore',
    optimySessionStore: 'optimySessionStore',
    spTrackerLabels: SnowplowTrackerLabels,
    privacyPolicy: 'https://d3vnu49f6j5f3z.cloudfront.net/documents/privacy-policy-jan-22-2021.pdf',
    ipfyUrl: 'https://api.ipify.org/?format=json',
    newWindowConfig: 'location=yes,height=570,width=520,scrollbars=yes,status=yes',
    defaultIp: '0.0.0.0',
    phoneRegex: '^[0-9]{10}$',
    bookingPhoneRegex: '^[\\w\\W]{14}$',
    phoneNumRegex: '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$',
    emailRegex: "^((([!#$%&\'*+\\-/=?^_`{|}~\\w])|([!#$%&\'*+\\-/=?^_`{|}~\\w][!#$%&\'*+\\-/=?^_`{|}~\\.\\w]{0,}[!#$%&\'*+\\-/=?^_`{|}~\\w]))[@]\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*)$",
    googleCalendar: 'https://www.google.com/calendar/render?action=TEMPLATE&text=',
    mccarthyTenantId: 7,
    cellcomTenantId: 5,
    postalCodeRegex: '^([A-Za-z]\\d[A-Za-z][ ]?\\d[A-Za-z]\\d)',
    configIframeUrl: 'booking-form-config',
    emailRegexForSalesTalentAgency: '^([\\w.-]+)@(\\[(\\d{1,3}\\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|icloud|bluewin|protonmail|t\\-online|web\\.|online\\.|aol\\.|live\\.)(([a-zA-Z\\d-]+\\.)+))([a-zA-Z]{2,63}|\\d{1,3})(\\]?)$'
}
