<div class="confirmation-panel" [ngClass]="{'fadeIn' : showConfirmationPanel,
                         'in-mobile': isMobile, 'landScape': isLandscape, 'portrait': !isLandscape}">
    <div class="op-d-flex op-justify-content-between"  [ngClass]="{'op-flex-column': !isLandscape && isMobile}">
        <div class="video-holder">
            <ng-container *ngIf="currentScreen === screenType.confirmationPanel">
                <img [src]="isMobile ? confirmationPanelMobImg : confirmationPanelImg" alt="image">
                <div class="see-us" [innerHTML]="'delonghiConfirmation.youSeeUs' | translate"></div>
                <div class="no-see-us">
                    <div class="no-video">
                        <img [src]="confirmationNoVideoImg" alt="image">
                    </div>
                    <span [innerHTML]="'delonghiConfirmation.weWillNotSeeYou' | translate"></span>
                </div>
            </ng-container>
            <ng-container *ngIf="currentScreen === screenType.audioPermissionPanel">
                <img [src]="audioPermissionImg" alt="image">
            </ng-container>
            <ng-container *ngIf="currentScreen === screenType.audioSetupPanel">
                <img [src]="isMobile ? audioSetupPanelMobImg : audioSetupPanelImg" alt="image">
            </ng-container>
        </div>
        <div class="confirmation-action">
            <ng-container *ngIf="currentScreen === screenType.confirmationPanel">
                <div class="op-d-flex op-flex-column op-align-items-center">
                    <div class="logo">
                        <img [src]="confirmationPanelLogo" alt="image">
                    </div>
                    <div class="title" [innerHTML]="'delonghiConfirmation.confirmationTitle' | translate"></div>
                    <button class="btn-cta" (click)="checkPermissionAndJoin()">
                        <svg-icon name="permission-camera"></svg-icon>
                        <span class="op-ml-2" [innerHTML]="'delonghiConfirmation.startLiveDemo' | translate"></span>
                    </button>
                    <a class="exit op-mb-3" (click)="emitNavigateUser.emit(false)" [innerHTML]="'delonghiConfirmation.noThank' | translate"></a>
                </div>
                <a class="external" [href]="faqUrl" [innerHTML]="'delonghiConfirmation.alreadyOwner' | translate"></a>
            </ng-container>
            <ng-container *ngIf="currentScreen === screenType.audioSetupPanel">
                <div class="op-d-flex op-flex-column op-justify-content-between setup">
                    <div [ngClass]="{'op-mt-3' : isMobile && !isLandscape}">
                        <div class="title" [innerHTML]="'delonghiConfirmation.audioSetupTitle' | translate"></div>
                        <div class="icon-area">
                            <div class="icon-area-title" [innerHTML]="'delonghiConfirmation.whatToExpect' | translate"></div>
                            <div class="op-d-flex op-justify-content-around op-align-items-center" [ngClass]="{'op-flex-column' : !isMobile}">
                                <div class="op-d-flex op-justify-content-center op-align-items-center op-flex-column op-mt-3">
                                    <div class="icon"><img [src]="confirmationNoVideoImg" alt="image"></div>
                                    <span class="op-mt-2 red" [innerHTML]="'delonghiConfirmation.weWillNotSeeYou' | translate"></span>
                                </div>
                                <div class="op-d-flex op-justify-content-center op-align-items-center op-flex-column op-mt-3">
                                    <div class="icon2"><svg-icon name="mic-on-icon"></svg-icon></div>
                                    <span class="op-mt-2" [innerHTML]="'delonghiConfirmation.weWillOnlyHearYou' | translate"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="op-d-flex op-align-items-center op-flex-column">
                        <button class="btn-cta" (click)="checkPermission()">
                            <svg-icon name="mic-on-icon"></svg-icon>
                            <span class="op-ml-2" [innerHTML]="'delonghiConfirmation.allowMic' | translate"></span>
                        </button>
                        <a class="exit op-d-flex op-justify-content-center op-mt-4" (click)="emitNavigateUser.emit(false);currentScreen = screenType.confirmationPanel" [innerHTML]="'delonghiConfirmation.noThank' | translate"></a>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentScreen === screenType.audioPermissionPanel">
                <div class="op-d-flex op-justify-content-between op-align-items-center op-flex-column permission">
                    <div class="op-d-flex op-justify-content-center op-align-items-center op-flex-column">
                        <img [src]="audioPermissionBoxImg" alt="img">
                        <div class="title op-mt-4" [innerHTML]="'delonghiConfirmation.pleaseTurnMic' | translate"></div>
                    </div>
                    <div class="op-d-flex op-justify-content-center op-align-items-center">
                        <button class="btn-cta" (click)="emitNavigateUser.emit(true)">
                            <span [innerHTML]="'common.continue' | translate"></span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
