<div class="video-container" [ngClass]="{'french': language === 'fr'}">
    <div class="video-container__call" [hidden]="!(isUserInQueue || isUserInCall)">
        <app-in-call [roomName]="roomName" [guestToken]="guestToken" [isOutboundCall]="isOutboundCall"
            (outboundCallAnswer)="outboundCallAnswer($event)"></app-in-call>
    </div>
    <div class="video-container__queue op-mb-3" *ngIf="showLoader && !isUserInCall">
        <div class="op-d-flex op-justify-content-end op-mt-3 call-waiting">
            <div class="fab-logo">
                <svg-icon name="mobile-fab"></svg-icon>
            </div>
        </div>
    </div>
    <div class="video-container__status" *ngIf="isUserInStatus && !isUserInQueue && !isUserInCall">
        <app-call-status-screen *ngIf="callStatusInfo" [iconName]="callStatusInfo.icon"
            [callStatus]="callStatusInfo.callStatus" [message]="callStatusInfo.msg"></app-call-status-screen>
    </div>
    <app-call-status-screen *ngIf="isLinkExpired" [iconName]="'session-link-expired'"
        [callStatus]="'inCall.callSessionLinkExpired'" [message]="'inCall.linkExpiredMsg'"></app-call-status-screen>
</div>
