import { BookingModalComponent } from './modules/booking/components/booking-modal/booking-modal.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CallInFutureComponent } from './components/call-in-future/call-in-future.component';
import { CloseRouteComponent } from './components/close-route/close-route.component';
import { MessageUsComponent } from './components/message-us/message-us.component';
import { UserFeedbackComponent } from './components/user-feedback/user-feedback.component';
import { UserInfoFormComponent } from './components/user-info-form/user-info-form.component';
import { VideoCallComponent } from './components/video-call/video-call.component';
import { ScheduleAppointmentDateTimeComponent } from './modules/booking/components/schedule-appointment-datetime/schedule-appointment-datetime.component';
import { ScheduledCallsInfoScreenComponent } from './components/scheduled-calls-info-screen/scheduled-calls-info-screen.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: SandboxComponent
  // },
  {
    path: 'user-info-form',
    outlet: 'plugin',
    component: UserInfoFormComponent
  },
  {
    path: 'call-in-future',
    outlet: 'plugin',
    component: CallInFutureComponent
  },
  {
    path: 'video-call',
    outlet: 'plugin',
    component: VideoCallComponent
  },
  {
    path: 'feedback',
    outlet: 'plugin',
    component: UserFeedbackComponent
  },
  {
    path: 'close',
    outlet: 'plugin',
    component: CloseRouteComponent
  },
  {
    path: 'live-call/:bookingStatus',
    outlet: 'plugin',
    component: ScheduleAppointmentDateTimeComponent
    // loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule)
  },
  {
    path: 'booking_off',
    outlet: 'plugin',
    component: ScheduledCallsInfoScreenComponent
  },
  {
    path: 'live-call/:bookingStatus',
    outlet: 'modal-outlet',
    component: BookingModalComponent
    // loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule)
  },
  {
    path: 'message-us',
    outlet: 'plugin',
    component: MessageUsComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
