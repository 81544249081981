import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AgentResponseData, AgentStatus, BookingConfig } from 'src/app/core/constants/agent.modal';
import {
  AllInputFields,
  BookingAppointment,
  FormControlNames,
  MaxCharLength,
  McCarthySchoolName
} from 'src/app/core/constants/booking-appointment.modal';
import { PluginStates, RoomInfoStates, UserInfo } from 'src/app/core/constants/common.enum';
import { Constants } from 'src/app/core/constants/constant';
import { SnowplowService } from 'src/app/core/services/snowplow/snowplow.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { PostMessageData } from '../models/models';

@Component({
  selector: 'app-schedule-appointment-form',
  templateUrl: './schedule-appointment-form.component.html',
  styleUrls: ['./schedule-appointment-form.component.scss']
})
export class ScheduleAppointmentFormComponent implements OnInit {
  bookingForm: UntypedFormGroup;
  alive = true;
  isSubmitted = false;
  bookingAppointment = BookingAppointment;
  showConfirmation = false;
  characterCount = '';
  maxCharactersLimit = MaxCharLength.maxCharactersLimit;
  mccarthyAccountInfo = McCarthySchoolName;
  checkedRadioButton: string;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  allInputFields: AllInputFields[] = [];
  postMessageData: PostMessageData;
  showIframe = false;
  bookingConfigData: any = {};
  userInformation: UserInfo | any;
  openSalesTalentForm = false;

  spTrackerLabels = Constants.spTrackerLabels;

  @Input() selectedDate: NgbDateStruct;
  @Input() selectedTime: string;
  @Input() agentDetails: AgentResponseData;
  @Input() editForm = false;
  @Input() duration: number;
  @Input() bookingConfig: BookingConfig;
  @Input() modalToShow: number;
  @Output() emitService = new EventEmitter();
  @Output() onsubmitClick = new EventEmitter();
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  constructor(private formBuilder: UntypedFormBuilder,
    private utils: UtilsService,
    private snowplowService: SnowplowService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    this.bookingConfigData = this.bookingConfig?.custom_form;
    this.userInformation = this.utils?.getLocalVal(PluginStates.roomInfo, 'userInfo');
    if(this.agentDetails?.tenant_id === 49){
      this.openSalesTalentForm = true;
      this.bookingForm = this.formBuilder.group({
        full_name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.pattern(Constants.emailRegexForSalesTalentAgency)]],
        phone: ['', [Validators.required]],
        company_name: ['', [Validators.required]],
        hiring_for: ['', [Validators.required]],
        comment: ['']
      });
      return;
    }
    if (this.bookingConfigData?.pages) {
      this.showIframe = true;
      this.bookingConfigData?.pages[0]?.elements.forEach((element: any) => {
        if (element?.name) {
          this.allInputFields.push({ name: element.name, label: element?.title?.default });
        }
      });
      return;
    } else {
      this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingForm, this.spTrackerLabels.screenShown,
        this.spTrackerLabels.genericForm, this.spTrackerLabels.version, 1);
    }
    this.createRequestAppointmentForm();
    this.setValues(this.userInformation);
  }

  createRequestAppointmentForm() {
    this.bookingForm = this.formBuilder.group({
      full_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(Constants.emailRegex)],],
      comment: ['']
    });
    if (this.checkIsMccarthy()) {
      this.bookingForm.addControl('schoolName', new UntypedFormControl('', Validators.required));
    }
  }

  checkIsMccarthy() {
    return this.agentDetails?.tenant_id === Constants.mccarthyTenantId;
  }

  setValues(userInfo: any) {
    if (userInfo) {
      let i = 0;
      while (i < FormControlNames?.length) {
        const value = FormControlNames[i];
        this.bookingForm?.controls[value]?.setValue(userInfo[value]);
        i++;
      }
      if (this.checkIsMccarthy()) {
        this.bookingForm?.controls['schoolName']?.setValue(userInfo['schoolName']);
      }
    }
    this.characterCount = userInfo?.comment ? userInfo?.comment : '';
  }

  getFormControlInfo(name: string) {
    return (this.bookingForm?.controls[name]?.touched && this.bookingForm?.controls[name]?.errors) ||
      (this.bookingForm?.controls[name]?.errors && this.isSubmitted);
  }

  setPhoneNumberValue(value: string){
    this.bookingForm.get('phone')?.setValue(value);
  }
  onSubmitIFrameForm(formValues: any) {
    if(this.modalToShow === 2){
      this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingForm, this.spTrackerLabels.continue, this.spTrackerLabels.customForm);
      this.bookingForm = new UntypedFormGroup({});
      this.bookingForm = this.utils.setFormControlsForIframe(this.bookingForm, this.allInputFields, formValues, true);
      this.emitUserInfo();
      this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.userInfo], [formValues]);
    }
  }

  emitUserInfo() {
    this.isSubmitted = true;
    this.onsubmitClick.emit({ form: this.bookingForm, formFields: this.allInputFields });
  }

  onSubmitForm() {
    this.emitUserInfo();

    const formValues = this.bookingForm?.controls;
    let userInfo: any = {
      full_name: formValues['full_name']?.value,
      email: formValues['email']?.value,
      comment: formValues['comment']?.value
    }

    if (this.checkIsMccarthy()) {
      userInfo['schoolName'] = formValues['schoolName'].value;
    }
    if(this.agentDetails?.tenant_id === 49){
      userInfo = {
        ...userInfo,
        hiring_for: formValues['hiring_for']?.value,
        company_name: formValues['company_name']?.value,
      }
    }

    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.userInfo], [userInfo]);
  }

  getmccarthyAccountInfo(value: any) {
    if (value) {
      const list = this.mccarthyAccountInfo?.filter((i: any) => {
        const joinStr = i.city ? `${i.schoolName} - ${i.city}` : i.schoolName;
        const checkValue = joinStr.toLowerCase().includes(value?.toLowerCase());
        if (checkValue) {
          return i;
        }
      });
      return list?.map((i: any) => i.city ? `${i.schoolName} - ${i.city}` : i.schoolName);
    } else {
      return this.mccarthyAccountInfo?.map(i => i.city ? `${i.schoolName} - ${i.city}` : i.schoolName);
    }
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term: any) => (this.getmccarthyAccountInfo(term))));
  }

  getSpecifyRequestPlaceHolder() {
    if (this.checkIsMccarthy()) {
      return this.translate.instant('mccarthyLiveChat.specifyRequest');
    }
    return this.translate.instant('placeholders.specifyRequest');
  }

  onBackClick() {
    this.emitService.emit(true);
  }

  close() {
    this.emitService.emit(false);
  }

}
