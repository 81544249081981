import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AgentResponseData, AgentStatus } from 'src/app/core/constants/agent.modal';
import {
  AllInputFields, BookingAppointment,
  BookingConfigRegularFields, FormControlNames, ScheduledInfoCreate,
  SubmitBookingSession,
  TimeZoneInfo
} from 'src/app/core/constants/booking-appointment.modal';
import { GuestStatus, PluginStates, RoomInfoStates } from 'src/app/core/constants/common.enum';
import { Constants } from 'src/app/core/constants/constant';
import { SnowplowService } from 'src/app/core/services/snowplow/snowplow.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { VideoCallService } from 'src/app/core/services/video-call/video-call.service';
import { environment } from '../../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-schedule-appointment-confirmation',
  templateUrl: './schedule-appointment-confirmation.component.html',
  styleUrls: ['./schedule-appointment-confirmation.component.scss']
})
export class ScheduleAppointmentConfirmationComponent implements OnInit {
  agentDetails: AgentResponseData;
  ipAddress: string;
  guest_user_id: string;
  showSuccessModal = false;
  sessionInfo: any;
  currentLanguage: string;
  extraFormFields: AllInputFields[] = [];
  spTrackerLabels = Constants.spTrackerLabels;
  clientUniqueID: string | any;
  currentLocale: string;
  isSubmitted = false;
  conflictErrorMsg = 'Booking slot no longer available';

  @Input() form: UntypedFormGroup;
  @Input() selectedDate: NgbDateStruct;
  @Input() selectedTime: string;
  @Input() formFieldsInfo: AllInputFields[];
  @Output() backEvent = new EventEmitter();
  @Output() confirmBookingEvent = new EventEmitter();
  @Output() scheduleConflictEvent = new EventEmitter();
  @Input() duration: number;
  @Input() editForm = false;
  @Input() timeZoneInfo: TimeZoneInfo;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private videoUserService: VideoCallService,
    private snowplowService: SnowplowService,
    private utils: UtilsService,
    private videoCallService: VideoCallService) {
    this.currentLanguage = this.utils.getLocalVal(PluginStates.language);
    this.currentLocale = this.utils.checkAndGetCurrentLang();
    this.videoCallService.updateCallSessionInfo$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.sessionInfo = res;
    });
  }

  ngOnInit(): void {
    this.clientUniqueID = this.snowplowService.getSnowplowUserId();
    this.getAndCheckCallInfo();
    this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingReview, this.spTrackerLabels.screenShown,
      this.editForm ? this.spTrackerLabels.reviewPageReschedule : this.spTrackerLabels.reviewPage, this.spTrackerLabels.version, 1);
    this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    if (this.formFieldsInfo?.length) {
      this.formFieldsInfo.map((f: any) => {
        if (f?.label && !BookingConfigRegularFields.includes(f.name) && f.name !== 'comment') {
          this.extraFormFields.push({ name: f.name, label: f.label });
        }
      });
    }
    if (!this.form && this.sessionInfo) {
      this.setBookingFormControlsAndValues();
    }
  }

  getAndCheckCallInfo(){
    const sessionInfo = this.utils.getLocalVal(PluginStates.bookingSessionInfo, 'response');
    if(!sessionInfo){
      this.utils.getCallInfoAndSetInfo();
    } else {
      this.sessionInfo = sessionInfo;
    }
  }


  onBackClick() {
    this.backEvent.emit(true);
  }

  setBookingFormControlsAndValues() {
    let i = 0;
    this.form = new UntypedFormGroup({});
    while (i < FormControlNames?.length) {
      const value = FormControlNames[i];
      this.form.addControl(value, new UntypedFormControl(this.sessionInfo[value]));
      i++;
    }
    if (this.sessionInfo?.phone && !this.sessionInfo.phone.includes('(')) {
      const splitPhone = this.sessionInfo.phone.replace('+1', '');
      this.form?.controls['phone']?.setValue(splitPhone.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3'));
    }
  }

  getTime() {
    if (this.selectedDate && this.selectedTime && this.currentLocale) {
      const selectedmonth = moment(this.selectedDate?.month, 'M').locale(this.currentLocale).format('MMM');
      const selectedDay = moment(`${this.selectedDate.year}/${this.selectedDate.month}/${this.selectedDate.day}`, 'YYYY-M-D').locale(this.currentLocale).format('ddd');
      return `${selectedDay}, ${selectedmonth} ${this.selectedDate.day}, ${this.selectedDate.year}   ${this.selectedTime}`;
    }
    return '';
  }

  rescheduleTime() {
    if(this.sessionInfo?.start_dt){
      const dateInLocalLang = moment(this.sessionInfo?.start_dt).locale(this.currentLocale);
      const activemonth = dateInLocalLang.format('MMM');
      const day = dateInLocalLang.format('ddd');
      const activeTime = dateInLocalLang.format('LT');
      const activeDate = dateInLocalLang.format('D');
      const activeYear = dateInLocalLang.format('YYYY');
      return `${day}, ${activemonth} ${activeDate}, ${activeYear}  ${activeTime}`
    }
    return '';
  }

  checkPreviousAndLatestBooking() {
    return this.rescheduleTime() !== this.getTime();
  }

  onSubmitForm() {
    this.isSubmitted = true;
    this.createSchedule();
  }

  createSchedule() {
    if (this.editForm) {
      const finalData = {
        start_dt: this.getStartDate(),
        duration: this.duration,
        queue_id: location.host === 'www.delonghi.com' ? (location.href.includes('/en-us') ? environment.en_us : environment.en_ca) : this.agentDetails?.default_queue_id
      };
      const inviteCode = this.utils.getSessionVal(RoomInfoStates.queueInviteCode);
      if (this.checkPreviousAndLatestBooking()) {
        this.videoUserService.updateScheduleInfo(inviteCode, finalData).subscribe(response => {
          this.utils.removeSessionStorage();
          this.storeInLocalStorage(response);
          this.sendEventOnBookingConfirmation();
        }, error => {
          this.sendEventOnBookingConfirmation();
          this.checkErrorAndRedirect(error);
        });
      } else {
        this.sendEventOnBookingConfirmation();
      }
      this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo]);
      return;
    }
    const finalData = this.createLiveChatInfo();
    this.videoUserService.submitLiveChatInfo(finalData).subscribe(response => {
      const guestToken = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestToken);
      this.utils.checkAndSetGuestStatus(GuestStatus.available, guestToken);
      this.storeInLocalStorage(response);
      const code = this.utils.getSessionVal(RoomInfoStates.queueInviteCode);
      if (code) {
        const bodyParams = { status: BookingAppointment.cancelled };
        this.videoUserService.updateScheduleInfo(code, bodyParams).subscribe(() => {
          this.utils.removeSessionStorage();
          const callWaiting = this.utils.getLocalVal(PluginStates.roomInfo, 'queuePositionId');
          if (callWaiting) {
            this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo]);
          }
          this.sendEventOnBookingConfirmation();
        }, () => {
          this.sendEventOnBookingConfirmation();
        });
      } else {
        this.utils.removeSessionStorage();
        this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo]);
        this.sendEventOnBookingConfirmation();
      }
    }, error => {
      this.checkErrorAndRedirect(error);
    });
    this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [RoomInfoStates.userInfo]);
  }

  checkErrorAndRedirect(error: any) {
    if (error.error === this.conflictErrorMsg && error.status === 409) {
      this.scheduleConflictEvent.emit();
    }
  }

  sendEventOnBookingConfirmation() {
    this.confirmBookingEvent.emit(true);
  }

  storeInLocalStorage(response: ScheduledInfoCreate) {
    if (response) {
      this.utils.setLocalVal(PluginStates.bookingSessionInfo, ['response'], [response]);
    }
  }

  close() {
    this.backEvent.emit(false);
  }
  createLiveChatInfo() {
    const formValues = this.form?.controls;
    const url = this.utils.getDelonghiVal('delonghi-QR')?.url;
    let attributes: any = {
      start_dt: this.getStartDate(),
      duration: this.duration,
      lang: this.currentLanguage ? this.currentLanguage : 'en',
      guest_tz: this.timeZoneInfo?.userTimeZone,
      guest_hostname: url ?  `${this.utils.removeExtraParamsFormUrl()}${url}` : this.utils.removeExtraParamsFormUrl(),
      guest_locale: navigator.language
    };

    Object.keys(this.form.controls).forEach(key => {
      if (!BookingConfigRegularFields.includes(key)) {
        attributes[key] = this.form.controls[key].value;
      }
    });

    if (formValues['schoolName']?.value) {
      const segmentValue = formValues['schoolName'].value.split(' - ');
      attributes = {
        ...attributes,
        city: segmentValue[1] ? segmentValue[1] : '',
        account_name: segmentValue[0]
      };
    }


    // const positionQueueId = JSON.parse(localStorage.getItem(CALL_WAITING));
    attributes = this.utils.buildCustomFieldsObject(attributes);
    attributes['domain_sessionid'] = this.utils.getDomainSessionId();
    const queueIdFromLocal = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queueId);
    let params: SubmitBookingSession = {
      tenantid: this.agentDetails?.tenant_id,
      full_name: formValues['full_name'].value,
      email: formValues['email'].value,
      user_agent: window.navigator.userAgent,
      queue_id: location.host === 'www.delonghi.com' ? (location.href.includes('/en-us') ? environment.en_us : environment.en_ca) : this.agentDetails?.default_queue_id,
      client_user_id: this.clientUniqueID,
      attr: JSON.stringify(attributes)
    };
    return params;
  }


  getFormatContactNumber(formValues: any) {
    if(formValues['phone'].value?.includes('+')){
      return formValues['phone']?.value?.replace(/[-]|[ ]/g, '');
    }
    return formValues['phone'].value?.length === 14 ? `+1${(formValues['phone'].value.replace(/[\W]/g, ''))}`
      : `+1${(formValues['phone'].value.replace(/[\W]/g, '')).slice(0, -1)}`;
  }

  getStartDate() {
    const dayInfo = this.selectedDate;
    return moment(`${dayInfo.day}-${dayInfo.month}-${dayInfo.year} ${this.selectedTime}`, 'D-M-YYYY LT').toISOString();
  }
}
