<div class="horizontal-controls" [ngClass]="{'open': menuOpen}">
    <div class="control-btn-list op-d-flex op-justify-content-start op-align-items-center">
        <control-button class="switch-video" iconNameOn="switch-video" iconNameOff="chat-new" [isOn]="!maximize" (clickEvent)="toggleFullScreen()">
        </control-button>
        <control-button iconNameOn="mic-on-icon" iconNameOff="mic-off-icon" [isOn]="isMicOn" [isDisabled]="micDisable"
                        [showError]="showMicError" [errorMessage]="'inCall.micError'" (clickEvent)="toggleMicrophone($event)">
        </control-button>
        <control-button iconNameOn="user-plus" (clickEvent)="toggleInvite()">
        </control-button>
        <control-button iconNameOn="settings" (clickEvent)="toggleSettings()">
        </control-button>
    </div>
</div>
