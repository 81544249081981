// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseApiUrl: 'https://optimy.app',
  CDN_URL: 'https://d2v9c5gw657g9w.cloudfront.net/icons',
  gaKey: 'GTM-59HFJ2K',
  cobrowseLicense: 'CtGcyc253NdTDg',
  appVersion: require('../../package.json').version,
  snowplowCollectorEndpoint: 'sp.optimy.ai',
  logRocketProjectIdentification: 'optimy/plugin-prod',
  environmentName: 'prod',
  production: true,
  devBack: false,
  devFront: false,
  uat: false,
  qa: false,
  local: false,
  en_ca: 109,
  en_us: 110,
  agentStatusApi: 'https://lambda.optimy.app/agentstatus'
};
