import {
  Component, ElementRef, EventEmitter,
  Input, OnInit, Output, ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbDatepickerConfig, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AgentResponseData, AgentStatus, BookingConfig, Exceptions } from 'src/app/core/constants/agent.modal';
import { AllInputFields, BookingFormInfoEvent, DateStructFormat, ScheduledInfoCreate, StartEndTime, StaticData, TimeSlots } from 'src/app/core/constants/booking-appointment.modal';
import { PluginStates, RoomInfoStates, RoutesUrls } from 'src/app/core/constants/common.enum';
import { Constants } from 'src/app/core/constants/constant';
import { DatepickerI18n } from 'src/app/core/constants/date-picker-i18';
import { SharedService } from 'src/app/core/services/shared/shared.service';
import { SnowplowService } from 'src/app/core/services/snowplow/snowplow.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { VideoCallService } from 'src/app/core/services/video-call/video-call.service';
import { AddSnowplowEventDirective } from 'src/app/modules/shared/directives/addSnowplowEvent/add-snowplow-event.directive';
import { GtmTrackers } from '../../../../core/constants/trackerLabels';
import { GtmService } from '../../../../core/services/gtm/gtm.service';

declare var require: any;
var moment = require('moment-timezone');

@Component({
  selector: 'app-schedule-appointment-datetime',
  templateUrl: './schedule-appointment-datetime.component.html',
  styleUrls: ['./schedule-appointment-datetime.component.scss'],
  providers:
    [{ provide: NgbDatepickerI18n, useClass: DatepickerI18n }]
})
export class ScheduleAppointmentDateTimeComponent implements OnInit {
  selectedDate: DateStructFormat;
  navigation = 'arrow';
  todayDate = moment();
  ipAddress: string;
  timeSlots = TimeSlots;
  selectedTime: string;
  bookingConfig: BookingConfig;
  editForm = false;
  duration: number;
  modalToShow = 1;
  bookingForm: UntypedFormGroup;
  agentDetails: AgentResponseData;
  upArrow = false;
  downArrow = false;
  container: HTMLElement;
  availableTimeSlots: string[] = [];
  fullTimeDateInfo: any = {};
  timeZone: string;
  userTimeZone: string;
  zone: string;
  scheduledCallInfo: any = {};
  bookingStatusParams: any;
  sessionInfo: ScheduledInfoCreate;
  bookingEditCancel: string;
  currentLanguage: string;
  formFieldsInfo: AllInputFields[] = [];

  currentDaysInfo: any;
  selectedEvent: string;
  openDatePicker: boolean;
  currentMonth: string;
  selectedDatePicker: any = {};
  showCancelModal: boolean;
  bookingStatus: any;
  DatePickerSelectedDate: DateStructFormat;
  utcOffSet: string;
  firstAvailableDate: any;
  showConflictErrorMsg = false;
  spTrackerLabels = Constants.spTrackerLabels;
  isLeftClick: boolean;
  availableSlots: number;
  availableDays: number;
  isNavigate = false;
  showDateSelectionText = false;

  hasAvailabilityForCalls: boolean = true;

  @ViewChild('timeElement') timeElement: ElementRef;
  @ViewChild('dp', { static: false }) private datePickernew: any;
  @ViewChild(AddSnowplowEventDirective) gaDirective: AddSnowplowEventDirective;
  @Output() closeModalEvent = new EventEmitter();
  @Input() isCallFuture = false;

  public openBookingModal: Subject<void> = new Subject<void>();
  public closeBookingModal: Subject<void> = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder,
    public config: NgbDatepickerConfig,
    private utils: UtilsService,
    private videoCallService: VideoCallService,
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private snowplowService: SnowplowService,
    private gtmService: GtmService,
  ) {
    this.currentLanguage = this.utils.checkAndGetCurrentLang();
  }

  ngOnInit(): void {
    this.gtmService.sendGtm(GtmTrackers.optimyBookingStarted);
    this.bookingStatus = this.route?.snapshot?.paramMap.get('bookingStatus') || 'booking';
    this.getAgentStatusInfo();
    this.getBookingConfigInfo().then(() => {
      if (this.bookingStatus.includes('edit')) {
        this.bookingStatusParams = this.bookingStatus;
        this.scheduledCallInfo = this.utils.getLocalVal(PluginStates.bookingSessionInfo, 'response');
        if (this.scheduledCallInfo) {
          this.utils.setLocalVal(null, [PluginStates.isBookingConfirmed], [false]);
          this.setPersonalInfo(this.scheduledCallInfo);
          this.onEditClick();
        } else {
          this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.bookingSessionInfo]);
          this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }]);
        }
        return;
      }
      this.getNavigationInfo({ next: this.getFullObjectOfDate(this.todayDate) }, false);
      this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingDateSelection, this.spTrackerLabels.screenShown,
        this.showConflictErrorMsg ? this.spTrackerLabels.miniDateError : this.spTrackerLabels.miniDate, this.spTrackerLabels.version, 1);
    });
  }

  getFullObjectOfDate(date: any) {
    return {
      year: parseInt(date.format('YYYY')), month:
        parseInt(date.format('M')), day: parseInt(date.format('D'))
    };
  }

  setPersonalInfo(res: any) {
    let userInfo: any = {
      full_name: res?.full_name,
      email: res?.email,
      comment: res?.comment,
      postal_code: res?.postal_code
    }
    if (res?.phone && !res.phone.includes('(')) {
      const splitPhone = res.phone.replace('+1', '');
      userInfo['phone'] = (splitPhone.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3'));
    }
    if (res?.custom_fields?.account_name) {
      userInfo['schoolName'] = `${res?.custom_fields?.account_name} - ${res?.custom_fields?.city}`;
    }
    this.utils.setLocalVal(PluginStates.roomInfo, [RoomInfoStates.userInfo], [userInfo]);
  }

  navigateFromDatePicker(event: any, value: boolean) {
    this.isLeftClick = event?.next?.year < event?.current?.year ||
      (event?.next?.year === event?.current?.year && event?.next.month < event?.current?.month);
    this.isNavigate = !!(event.next && event.current);
    this.getNavigationInfo(event, value)
  }

  getNavigationInfo(event: any, value?: boolean, isEdit?: boolean) {
    if (value) {
      this.todayDate = moment();
    }
    this.currentMonth = this.getMonthFormat();
    moment.locale('en');
    let selectedMonthAndDate = moment(`${event.next.year}-${moment(event.next.month, 'M').format('MM')}`, 'YYYY-MM');
    let previousDay = moment(selectedMonthAndDate).subtract(1, 'days');
    let todayDay = 0;
    const todayMonth = this.todayDate.format('M');
    if (todayMonth == event.next.month) {
      previousDay = moment(this.todayDate).subtract(1, 'days');
      if (todayMonth == previousDay.format('M')) {
        todayDay = previousDay.format('D');
      }
    }
    value ? this.getFullArrayOfDateAndTimeForDatePicker(selectedMonthAndDate, previousDay, todayDay) :
      this.getFullArrayOfDateAndTime(selectedMonthAndDate, previousDay, isEdit);
  }

  getFullArrayOfDateAndTime(selectedMonthAndDate: any, previousDay: any, isEdit?: boolean) {
    let daysInMonth = selectedMonthAndDate.daysInMonth();
    const nextDay = moment(this.todayDate).add(3, 'days');
    let arrDays: any = {};
    arrDays[nextDay.format(StaticData.dateFormat)] = (this.setArrayOfDateAndTime(nextDay));
    arrDays = this.commonFullArrayOfDateAndTime(arrDays, nextDay);
    let current = moment(this.todayDate);
    for (let i = 0; i <= 2; i++) {
      arrDays = this.commonFullArrayOfDateAndTime(arrDays, current);
      current = moment(current).add(1, 'days');
    }

    arrDays[previousDay.format(StaticData.dateFormat)] = (this.setArrayOfDateAndTime(previousDay));
    arrDays = this.commonFullArrayOfDateAndTime(arrDays, previousDay);
    this.fullTimeDateInfo = this.checkAndSetTimeSlots(arrDays);
    if (!this.checkTimeZone()) {
      this.convertToUserTimeZone(arrDays, true, isEdit);
    } else {
      this.commonCheckDateInfo(isEdit);
    }
  }
  getFullArrayOfDateAndTimeForDatePicker(selectedMonthAndDate: any, previousDay: any, todayDay: number) {
    let daysInMonth = selectedMonthAndDate.daysInMonth();
    const nextDay = moment(selectedMonthAndDate).add(1, 'months').startOf('month');
    let arrDays: any = {};
    arrDays[nextDay.format(StaticData.dateFormat)] = (this.setArrayOfDateAndTime(nextDay));
    while (daysInMonth > todayDay) {
      let current = moment(selectedMonthAndDate).date(daysInMonth);
      arrDays = this.commonFullArrayOfDateAndTime(arrDays, current);
      daysInMonth--;
    }
    arrDays[previousDay.format(StaticData.dateFormat)] = (this.setArrayOfDateAndTime(previousDay));
    this.fullTimeDateInfo = this.checkAndSetTimeSlots(arrDays);
    this.findNoOfAvailableDays();
    if (!this.checkTimeZone()) {
      this.convertToUserTimeZone(arrDays, false);
    }
  }

  findNoOfAvailableDays() {
    let count = 0;
    Object.keys(this.fullTimeDateInfo).forEach((days: any) => {
      if (this.fullTimeDateInfo[days]?.availableTimeSlots?.length) {
        count = count + 1;
      }
    });
    this.availableDays = count;
    if (this.isNavigate) {
      this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingDateSelection,
        this.isLeftClick ? this.spTrackerLabels.left : this.spTrackerLabels.right,
        this.editForm ? this.spTrackerLabels.fullMonthReschedule : this.spTrackerLabels.fullMonth,
        this.spTrackerLabels.availableDays, this.availableDays);
    }
  }

  commonFullArrayOfDateAndTime(arrDays: any, current: any) {
    arrDays[current.format(StaticData.dateFormat)] = (this.setArrayOfDateAndTime(current));
    let dateInfo = this.bookingConfig?.daily[arrDays[current.format(StaticData.dateFormat)]?.day];
    // Somehow, the dateInfo is not allowing to be modified. So we are deeply copying the object to remove that.
    dateInfo = JSON.parse(JSON.stringify(dateInfo));
    const checkDate: string | any = Object.keys(this.bookingConfig?.daily?.exceptions)?.find(i => i === current.format(StaticData.dateFormat));
    let exceptionDate = this.bookingConfig?.daily?.exceptions[checkDate] ?
      [...this.bookingConfig?.daily?.exceptions[checkDate]] : null;
    if (exceptionDate?.length && this.checkLengthAndValues(exceptionDate)) {
      let firstException = { ...exceptionDate[0] };
      firstException.date = current.format(StaticData.dateFormat);
      exceptionDate[0] = { ...firstException };
      arrDays[current.format(StaticData.dateFormat)] = { ...arrDays[current.format(StaticData.dateFormat)], dateInfo: exceptionDate };
    } else {
      if (this.checkLengthAndValues(dateInfo)) {
        dateInfo[0].date = current.format(StaticData.dateFormat);
        arrDays[current.format(StaticData.dateFormat)] = { ...arrDays[current.format(StaticData.dateFormat)], dateInfo: dateInfo };
      }
    }
    return arrDays;
  }

  checkAndSetTimeSlots(arrDays: any) {
    let keysOfArrDays = Object.keys(arrDays).sort((a, b) => {
      const date1: any = new Date(a);
      const date2: any = new Date(b);
      return date1 - date2;
    });
    keysOfArrDays.forEach((day: string, index: number) => {
      if (arrDays[day]?.dateInfo) {
        const nextDate = keysOfArrDays[index + 1];
        const availableTimeSlots = this.getTimeSlots(arrDays[day].dateInfo, arrDays[nextDate]?.dateInfo);
        arrDays[day] = { ...arrDays[day], timeSlots: this.timeSlots, availableTimeSlots: availableTimeSlots }
      }
    })
    return arrDays;
  }

  convertToUserTimeZone(arrDays: any, isCurrentInfo?: boolean, isEdit?: boolean) {
    let fullMonthTimeSlots: string[] = [];
    let fullMonthAvailableTimeSlots: string[] = [];

    Object.keys(arrDays)?.map(i => {
      if (arrDays[i]?.timeSlots?.length) {
        let convertTimeSlots = this.convertAllTimeSlots(arrDays[i]?.date, arrDays[i]?.timeSlots);
        arrDays[i] = { ...arrDays[i], timeSlots: convertTimeSlots };
        fullMonthTimeSlots.push(...arrDays[i].timeSlots);
      }
      if (arrDays[i]?.availableTimeSlots?.length) {
        let convertAvailableTimes = this.convertAllTimeSlots(arrDays[i]?.date, arrDays[i]?.availableTimeSlots);
        arrDays[i] = { ...arrDays[i], availableTimeSlots: convertAvailableTimes };
        fullMonthAvailableTimeSlots.push(...arrDays[i].availableTimeSlots);
      }
    });

    this.fullTimeDateInfo = arrDays;
    this.fillConvertedAllTimeSlots(fullMonthTimeSlots, 'finalTimeSlots');
    this.fillConvertedAllTimeSlots(fullMonthAvailableTimeSlots, 'finalAvailableTimeSlots');
    if (isCurrentInfo) {
      this.commonCheckDateInfo(isEdit);
    }
  }

  commonCheckDateInfo(isEdit?: boolean) {
    if (this.firstAvailableDate) {
      this.setCurrentDaysInfo();
    } else {
      this.checkFirstAvailabilityDate(isEdit);
    }
  }


  convertAllTimeSlots(date: string, timeSlot: any) {
    let convertAllTimes: any = [];
    for (let j = 0; j < timeSlot?.length; j++) {
      let convertDate = moment(new Date(`${date} ${timeSlot[j]} UTC${this.utcOffSet}`)).tz(this.userTimeZone);
      convertAllTimes.push(convertDate.format('YYYY-MM-DD,LT.z'));
    }
    return convertAllTimes;
  }

  fillConvertedAllTimeSlots(fullMonthAllSlots: any, value: string) {
    fullMonthAllSlots.map((i: any) => {
      const dateSplit = i.split(',');
      let findKey = this.fullTimeDateInfo[dateSplit[0]];
      if (findKey && !findKey[value]) {
        this.fullTimeDateInfo[dateSplit[0]][value] = [];
        this.fullTimeDateInfo[dateSplit[0]][value].push(this.getTimeFormat(dateSplit[1].split('.')[0]));
      } else {
        this.fullTimeDateInfo[dateSplit[0]][value].push(this.getTimeFormat(dateSplit[1].split('.')[0]));
      }
    });
  }

  getDayFormat(day: any, value: boolean) {
    const splitDateDay = day?.fullDay?.split(':');
    return value ? splitDateDay[1] : splitDateDay[0];
  }

  getTimeFormat(time: string) {
    return moment(`${time}`, 'LT').locale(this.currentLanguage).format('LT');
  }

  getMonthFormat() {
   return moment(this.todayDate).locale(this.currentLanguage).format('MMM Y');
  }

  checkFirstAvailabilityDate(isEdit?: boolean) {
    const fullInfokeys = Object.keys(this.fullTimeDateInfo);
    const dateFormat = 'YYYY-MM-DD';
    for (let i = 1; i <= 3; i++) {
      const objectValue: string = fullInfokeys[i];
      const checkAvailableDate = !this.checkDisabledDate(this.fullTimeDateInfo[objectValue]);
      if (checkAvailableDate) {
        this.firstAvailableDate = moment(objectValue, dateFormat);
        this.config.minDate = this.getFullObjectOfDate(this.firstAvailableDate);
        const lastBookingLimitDate = moment(new Date()).add(this.bookingConfig.defaults.booking_days_limit, 'days');
        if(moment(this.firstAvailableDate).isBefore(lastBookingLimitDate)){
          this.showDateSelectionText = true;
        } else {
          this.showDateSelectionText = false;
        }
        if (isEdit) {
          this.setSelectedValueForEdit();
        } else {
          this.setCurrentDaysInfo();
        }
        return;
      } else {
        this.todayDate = moment(fullInfokeys[i + 1], dateFormat);
        this.getNavigationInfo({ next: this.getFullObjectOfDate(moment(fullInfokeys[i + 1], dateFormat)) }, false, isEdit);
        return;
      }
    }
  }

  setCurrentDaysInfo() {
    this.currentDaysInfo = [];
    const fullInfokeys = Object.keys(this.fullTimeDateInfo);
    for (let i = 1; i <= 3; i++) {
      const objectValue: string = fullInfokeys[i];
      this.currentDaysInfo.push(this.fullTimeDateInfo[objectValue]);
    }
    this.availableSlots = 0;
    this.currentDaysInfo.forEach((days: any) => {
      this.availableSlots = this.availableSlots + (days?.availableTimeSlots ? days?.availableTimeSlots?.length : 0)
    })
    if (this.isNavigate) {
      this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingDateSelection,
        this.isLeftClick ? this.spTrackerLabels.left : this.spTrackerLabels.right,
        this.editForm ? this.spTrackerLabels.miniDateReschedule : this.spTrackerLabels.miniDate,
        this.spTrackerLabels.availableSlots, this.availableSlots);
    }
  }

  onClickOfNextOrPrevious(value: boolean) {
    this.isLeftClick = !value;
    this.isNavigate = true;
    if (value) {
      this.todayDate = moment(this.todayDate).add(3, 'days');
    } else {
      if (this.checkArrowDisabled()) {
        return;
      }
      this.todayDate = moment(this.todayDate).subtract(3, 'days');
    }
    this.getNavigationInfo({ next: this.getFullObjectOfDate(this.todayDate) }, false);
  }

  checkArrowDisabled() {
    const previousDate = moment(this.todayDate).subtract(1, 'days');
    if (moment(previousDate).isBefore(this.firstAvailableDate)) {
      return true;
    }
    return false;
  }

  createTodayDate(requireDate: any) {
    this.todayDate = moment(`${requireDate.year}-${requireDate.month}-${requireDate.day}`, 'YYYY-M-D');
  }

  setDate(event: any) {
    this.setSelectedDate(event);
    this.container = this.timeElement?.nativeElement;
    setTimeout(() => {
      this.scrollToDiv(this.availableTimeSlots[0]);
    }, 300);
  }

  setSelectedDate(day: any) {
    const dateInFormat = day.dateStruct;
    if (!this.isDateDisabled(dateInFormat)) {
      this.selectedEvent = this.getSelectedDateFormat(dateInFormat);
      this.selectedDate = dateInFormat;
      const selectedDay = this.getFormatDate(this.selectedDate);
      const timeSlotData = this.fullTimeDateInfo[selectedDay];
      if (timeSlotData) {
        const fullTimeSlotData = this.getTimeSlotsAccordingToTimeZone(timeSlotData);
        this.timeSlots = fullTimeSlotData?.timeSlots?.sort(this.sortHours(selectedDay));
        this.availableTimeSlots = fullTimeSlotData?.availableTimeSlots?.sort(this.sortHours(selectedDay));
      }
      if (!this.availableTimeSlots?.includes(this.selectedTime)) {
        this.selectedTime = '';
      }
      if (!this.bookingConfig?.defaults?.lead_time_days &&
        this.getFormatDate(this.selectedDate) === this.getFormatDate(this.getOriginalTodayDate())) {
        const currentTime = moment().format('LT');
        this.availableTimeSlots = this.availableTimeSlots?.filter(x => {
          return moment(this.convertToIsoString(selectedDay, x)).isAfter(this.convertToIsoString(selectedDay, currentTime));
        });
        this.availableTimeSlots?.splice(0, (this.bookingConfig?.defaults?.lead_time_mins / this.bookingConfig?.defaults?.interval));
      }
    }
  }

  convertToIsoString(selectedDay: any, time: string) {
    return moment(`${selectedDay} ${time}`, 'YYYY-MM-DD LT').toISOString();
  }

  sortHours(selectedDay: any) {
    return (a: any, b: any) => {
      const date1: any = new Date(`${selectedDay} ${a}`);
      const date2: any = new Date(`${selectedDay} ${b}`);
      return date1 - date2;
    };
  }

  getOriginalTodayDate() {
    const originalDate = moment();
    return {
      year: originalDate.format('YYYY'), month:
        originalDate.format('M'), day: originalDate.format('D')
    };
  }

  scrollToDiv(time: string) {
    for (let i = 0; i < this.timeElement?.nativeElement?.children?.length; i++) {
      if (this.timeElement?.nativeElement?.children[i]?.id === time) {
        this.timeElement?.nativeElement?.children[i]?.scrollIntoView({ block: 'center', inline: 'nearest' });
      }
    }
  }

  setTime(time: string) {
    this.showConflictErrorMsg = false;
    this.selectedTime = time;
  }

  getTimeSlotsAccordingToTimeZone(timeSlotData: any) {
    const timeSlots = this.checkTimeZone() ? timeSlotData?.timeSlots : timeSlotData?.finalTimeSlots;
    const availableTimeSlots = this.checkTimeZone() ? timeSlotData?.availableTimeSlots : timeSlotData?.finalAvailableTimeSlots;
    return { timeSlots: timeSlots, availableTimeSlots: availableTimeSlots };
  }

  getFormatDate(date: any) {
    return moment(`${date.year}/${date.month}/${date.day}`, 'YYYY-M-D').format(StaticData.dateFormat);
  }

  getDisabledValue() {
    return !this.selectedTime || !this.selectedDate;
  }

  checkEmitService(value: any) {
    if (value) {
      if (this.selectedTime) {
        this.todayDate = moment(this.getFormatDate(this.selectedDate));
        this.getNavigationInfo({ next: { year: this.selectedDate?.year, month: this.selectedDate?.month } }, false);
        this.checkSetTimeOut(this.selectedDate);
      }
      if (this.editForm) {
        this.modalToShow = 1;
        this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingDateSelection, this.spTrackerLabels.screenShown,
          this.spTrackerLabels.miniDateReschedule, this.spTrackerLabels.version, 1);
        return;
      }
      this.modalToShow--;
      return;
    }
    this.sharedService.showBookingAndCheckPreviousState(false);
  }

  setArrayOfDateAndTime(day: any) {
    return {
      date: day.format('MMM DD Y'), day: day.format('dddd').toLowerCase(),
      fullDay: `${this.getLocaleFormatFromMoment(day, 'DD')}:${this.getLocaleFormatFromMoment(day, 'ddd')}`,
      dateStruct: this.getFullObjectOfDate(day)
    };
  }

  getLocaleFormatFromMoment(value: any, newFormat: string) {
    return moment(value).locale(this.currentLanguage).format(newFormat);
  }

  checkLengthAndValues(date: any) {
    return (date?.length && date[0]?.start && date[0]?.end);
  }

  getTimeSlots(dateInfo: Exceptions[], nextDayInfo?: Exceptions[]) {
    let items: any = [];
    let start = moment(`${dateInfo[0]?.date} ${dateInfo[0]?.start}`);
    let end = moment(`${dateInfo[0]?.date} ${dateInfo[dateInfo?.length - 1]?.end}`);
    const interval = this.bookingConfig?.defaults?.interval;
    this.timeSlots = this.getFullArrayOfTime(start, end, interval, items);
    return this.getAvailableTime(dateInfo, interval, nextDayInfo);
  }

  getFullArrayOfTime(start: any, end: any, interval: number, items: any, nextDayInfo?: Exceptions[], date?: string) {
    const timeDiff = (this.duration - interval);
    for (let current = start; current < end; current.add(interval, 'minutes')) {
      let currentTime = moment(current).add(timeDiff, 'minutes');
      if (currentTime < end) {
        items.push(moment(current).locale(this.currentLanguage).format('LT'));
      } else {
        const timeFormat = 'HH:mm';
        const endFormat = moment(end).format(timeFormat);
        if (endFormat === StartEndTime.endTime && nextDayInfo?.length
          && nextDayInfo[0]?.start === StartEndTime.startTime) {
          const lastSlot = moment(`${date} ${items[items.length - 1]}`, 'YYYY-MM-DD LT').format(timeFormat);
          const lastSlotMoment = moment(`${date} ${lastSlot}`).add(interval, 'minutes');
          const nextDayStartTime = moment(`${nextDayInfo[0]?.date} ${nextDayInfo[0]?.start}`);
          for (let startTime = lastSlotMoment; startTime < nextDayStartTime; startTime.add(interval, 'minutes')) {
            items.push(moment(startTime).locale(this.currentLanguage).format('LT'));
          }
        }
      }
    }
    return items;
  }

  checkIncludeTime(time: string) {
    return !this.availableTimeSlots?.includes(time);
  }

  getAvailableTime(dateInfo: Exceptions[], interval: number, nextDayInfo?: Exceptions[]) {
    let times: any = [];
    let allTimeSlots: any = [];
    let start: any;
    let end: any;
    for (let i = 0; i < dateInfo?.length; i++) {
      if (dateInfo[i]?.end === dateInfo[i + 1]?.start) {
        if (!start) {
          start = moment(`${dateInfo[0]?.date} ${dateInfo[i]?.start}`);
        }
        end = moment(`${dateInfo[0]?.date} ${dateInfo[i + 1]?.end}`);
        continue;
      }
      else {
        if (start && end) {
          allTimeSlots = this.getFullArrayOfTime(start, end, interval, times, nextDayInfo, dateInfo[0]?.date);
        } else {
          start = moment(`${dateInfo[0]?.date} ${dateInfo[i]?.start}`);
          end = moment(`${dateInfo[0]?.date} ${dateInfo[i]?.end}`);
          allTimeSlots = this.getFullArrayOfTime(start, end, interval, times, nextDayInfo, dateInfo[0]?.date);
        }
        start = null;
        end = null;
      }
    }
    return allTimeSlots;
  }

  checkTimeZone() {
    return this.timeZone === this.zone;
  }

  getAgentStatusInfo() {
    this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    if (!this.agentDetails) {
      this.utils.getAgentStatusFormRedisCaChe().subscribe((res: AgentResponseData | any) => {
        this.setAgentStatusRes(res);
      }, error => {
        console.log('agent status error', error);
      });
    }
  }

  setAgentStatusRes(res: AgentResponseData | any){
    // when we implement state management add this function call to effects and remove from here
    this.utils.setAgentStatusToSessionStorage(res);
    this.agentDetails = res;
  }

  getBookingConfigInfo() {
    return new Promise((resolve) => {
      this.videoCallService.getBookingConfig().subscribe((config: BookingConfig) => {
        this.utils.setSessionVal([PluginStates.bookingConfig], [config]);
        this.bookingConfig = config;
        this.duration = this.bookingConfig?.defaults?.duration;
        this.timeZone = moment.tz(this.bookingConfig?.defaults?.timezone).zoneAbbr();
        this.utcOffSet = moment.tz(this.bookingConfig?.defaults?.timezone).format('Z');
        this.userTimeZone = moment.tz.guess();
        this.zone = moment.tz(this.userTimeZone).zoneAbbr();
        this.hasAvailabilityForCalls = this.isBookingAllowedAndHasAvailability(config);
        resolve(0);
      })
    });
  }


  private isBookingAllowedAndHasAvailability(config: BookingConfig): boolean {
    let countConfigurationAvailable = 0;
    countConfigurationAvailable += config.daily.monday.length;
    countConfigurationAvailable += config.daily.tuesday.length;
    countConfigurationAvailable += config.daily.wednesday.length;
    countConfigurationAvailable += config.daily.thursday.length;
    countConfigurationAvailable += config.daily.friday.length;
    countConfigurationAvailable += config.daily.saturday.length;
    countConfigurationAvailable += config.daily.sunday.length;
    return !config.defaults.disabled && (countConfigurationAvailable > 0 || this.hasExceptionCallInTheFuture(config));
  }

  private hasExceptionCallInTheFuture(config: BookingConfig): boolean {
    let hasExceptionCallInTheFuture = false;
    if (config.daily.exceptions) {
      const currentDate = moment().format('YYYY-MM-DD');
      Object.keys(config.daily.exceptions).forEach((key) => {
        if (key > currentDate) {
          hasExceptionCallInTheFuture = true;
        }
      });
    }
    return hasExceptionCallInTheFuture;
  }

  onSubmitClick() {
    this.showConflictErrorMsg = false;
    if (this.editForm) {
      this.modalToShow = 3;
      this.isNavigate = false;
      return;
    }
    this.modalToShow = 2;
    this.isNavigate = false;
  }

  isDateDisabled(date: any) {
    const currentDay = this.getOriginalTodayDate();
    const start = this.ngbStructToDate(currentDay);
    return moment(this.ngbStructToDate(date)).isBefore(start);
  }

  getStartDate() {
    let checkEndDisable;
    checkEndDisable = (date: NgbDate, current: { year: number, month: number }) => {
      return this.commonDisableCheck(date) || this.checkBookingDaysLimit(date);
    };
    return checkEndDisable;
  }

  checkDisabledDate(day: any) {
    const date = day.dateStruct;
    return this.commonDisableCheck(date) || this.checkBookingDaysLimit(date);
  }

  checkBookingDaysLimit(date: any){
    if(this.bookingConfig?.defaults?.booking_days_limit && this.firstAvailableDate){
      const lastBookingLimitDate = moment(new Date()).add(this.bookingConfig.defaults.booking_days_limit, 'days');
      const formattedDate = this.ngbStructToDate(date);
      return !moment(formattedDate).isBefore(lastBookingLimitDate) ? true : false;
    }
    return false;
  }

  commonDisableCheck(date: any) {
    const futDate = this.ngbStructToDate(date);
    const lastLimitDate = moment(new Date()).add(this.bookingConfig?.defaults?.lead_time_days - 1, 'days');
    return this.bookingConfig?.defaults?.lead_time_days ? this.getDisabledDate(date) || moment(futDate).isBefore(lastLimitDate) :
      this.getDisabledDate(date);
  }

  ngbStructToDate(date: any): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  checkDisabledTimeSlots(date: any, formatDate: string) {
    const timeSlotData = this.fullTimeDateInfo[formatDate];
    const fullTimeData = this.getTimeSlotsAccordingToTimeZone(timeSlotData);
    const formatedDate = this.getFormatDate(date);
    if (timeSlotData) {
      if (!fullTimeData?.timeSlots?.length || !fullTimeData?.availableTimeSlots?.length) {
        return this.openDatePicker ? this.ngbStructToDate(date) : true;
      }
    }
    if (!this.bookingConfig?.defaults?.lead_time_days &&
      formatedDate === this.getFormatDate(this.getOriginalTodayDate()) && timeSlotData) {
      const currentTime = moment().format('LT');
      fullTimeData.availableTimeSlots = fullTimeData?.availableTimeSlots?.filter((x: any) => {
        return moment(this.convertToIsoString(formatedDate, x)).isAfter(this.convertToIsoString(formatedDate, currentTime));
      });
      fullTimeData?.availableTimeSlots?.splice(0, (this.bookingConfig?.defaults?.lead_time_mins / this.bookingConfig?.defaults?.interval));
      if (!fullTimeData?.availableTimeSlots.length) {
        return this.openDatePicker ? this.ngbStructToDate(date) : true;
      }
    }
    return false;
  }

  getDisabledDate(date: any, start?: any) {
    const formatDate = this.getFormatDate(date);
    return this.isDateDisabled(date) || this.checkExceptionDate(formatDate) || this.checkDisabledTimeSlots(date, formatDate);
  }

  checkExceptionDate(date: string){
    const exceptionDateValue =  this.bookingConfig?.daily?.exceptions[date]?.length === 0;
      if(exceptionDateValue){
        return true;
      }
    return false;
  }

  onNextClick(event?: BookingFormInfoEvent) {
    if (event) {
      this.bookingForm = event.form;
      this.formFieldsInfo = event.formFields;
    }
    this.modalToShow++;
    if (this.modalToShow == 4) {
      this.utils.setLocalVal(null, [PluginStates.isBookingConfirmed], [true]);
    }
  }

  onEditClick() {
    this.sessionInfo = this.utils.getLocalVal(PluginStates.bookingSessionInfo, 'response');
    this.editForm = true;
    this.modalToShow = 1;
    this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingDateSelection, this.spTrackerLabels.screenShown,
      this.editForm ? this.spTrackerLabels.miniDateReschedule : this.spTrackerLabels.miniDate, this.spTrackerLabels.version, 1);
    this.snowplowService.trackStructEvent(this.spTrackerLabels.inviteLink, this.spTrackerLabels.editBooking,
      this.spTrackerLabels.externalLink, this.spTrackerLabels.callId, this.sessionInfo.queue_position_id);
    this.getNavigationInfo({ next: this.getFullObjectOfDate(this.todayDate) }, false, true);
  }

  onScheduleConflictClick() {
    this.availableTimeSlots.splice(this.availableTimeSlots.indexOf(this.selectedTime), 1);
    this.getBookingConfigInfo().then(() => {
      this.showConflictErrorMsg = true;
      if (this.selectedTime) {
        this.todayDate = moment(this.getFormatDate(this.selectedDate));
        this.getNavigationInfo({ next: { year: this.selectedDate?.year, month: this.selectedDate?.month } }, false);
        this.checkSetTimeOut
          (this.selectedDate);
      }
      this.modalToShow = 1;
      this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingDateSelection, this.spTrackerLabels.screenShown, this.spTrackerLabels.miniDateError, this.spTrackerLabels.version, 1);
    })
  }

  setSelectedValueForEdit() {
    const selectedYear = parseInt(moment(this.sessionInfo?.start_dt).format('YYYY'));
    const selectedMonth = parseInt(moment(this.sessionInfo?.start_dt).format('M'));
    const eventDate = {
      year: selectedYear,
      month: selectedMonth,
      day: parseInt(moment(this.sessionInfo?.start_dt).format('D')),
    };
    this.createTodayDate(eventDate);
    this.getNavigationInfo({ next: { year: selectedYear, month: selectedMonth } }, false);
    this.selectedTime = moment(this.sessionInfo?.start_dt).format('LT');
    if (this.selectedTime) {
      this.checkSetTimeOut(eventDate);
    }
  }

  getSelectedDateFormat(dateInfo: any) {
    return moment(`${dateInfo?.month} ${dateInfo?.day} ${dateInfo?.year}`, 'M D YYYY').format('MMM DD Y');
  }

  openFullDatePicker() {
    this.openDatePicker = true;
    this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingDateSelection,
      this.spTrackerLabels.fullDate, this.editForm ? this.spTrackerLabels.miniDateReschedule : this.spTrackerLabels.miniDate);
    this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingDateSelection, this.spTrackerLabels.screenShown,
      this.editForm ? this.spTrackerLabels.fullMonthReschedule : this.spTrackerLabels.fullMonth, this.spTrackerLabels.version, 1);
    this.DatePickerSelectedDate = this.selectedDate;
    const date = this.selectedDate;
    const dateInfo = moment(this.todayDate).format('M') == this.selectedDate?.month ? this.getFullObjectOfDate(this.todayDate) :
      this.selectedDate;
    if (this.selectedDate) {
      setTimeout(() => {
        this.getNavigationInfo({ next: { year: date.year, month: date.month } }, true);
      }, 100);
      setTimeout(() => {
        this.datePickernew?.navigateTo({ year: dateInfo?.year, month: dateInfo?.month });
      }, 400);
    }
  }

  closeDatePicker() {
    this.selectedDate = this.DatePickerSelectedDate;
    this.openDatePicker = false;
    this.checkSelectedDateOrNot();
    setTimeout(() => {
      if (this.selectedDate) {
        this.setDate({ dateStruct: this.selectedDate });
      }
    }, 100);
  }

  getNgbDatePickerSelectedValue() {
    return !!this.datePickernew?.model?.selectedDate;
  }
  checkSetTimeOut(dateInfo: any) {
    setTimeout(() => {
      this.setSelectedDate({ dateStruct: dateInfo });
    }, 300);
    setTimeout(() => {
      this.scrollToDiv(this.selectedTime);
    }, 500);
  }

  closeModal() {
    this.closeBookingModal.next();
  }

  getTimeZoneInfo() {
    return { userTimeZone: this.userTimeZone, zone: this.zone };
  }

  onRescheduleCancelClick(event: string) {
    this.bookingEditCancel = event;
    this.showCancelModal = true;
    this.openBookingModal.next();
  }

  onBackClick(value: boolean) {
    this.showCancelModal = false;
    this.closeModal();
  }

  onContinueClose() {
    this.closeModal();
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }]);
  }

  checkSelectedDateOrNot() {
    if (this.selectedDate?.day) {
      this.createTodayDate(this.selectedDate);
    }
    this.getNavigationInfo({ next: this.getFullObjectOfDate(this.todayDate) }, false);
  }

  onCancelClick() {
    this.todayDate = this.firstAvailableDate;
    this.isNavigate = false;
    if (this.todayDate) {
      this.checkSelectedDateOrNot();
    }
    if (this.openDatePicker) {
      this.openDatePicker = false;
      return;
    }
    this.sharedService.showBookingAndCheckPreviousState(false);
  }

}
