<div class="chat-window" *ngIf="!isOutboundCall"
    [ngClass]="{'maximize' : maximize, 'maximize-landscape-view': maximizeLandscapeView}">
    <button class="scroll-to-bottom" *ngIf="showToBottomBtn" (click)="updateConsumedIndex()">
        <svg-icon name="scroll-down"></svg-icon>
    </button>
    <div class="chat-window__message" id="chatDivElem" [ngClass]="{'routing' : inRoutingState && !textEnabledForRouting }" #chatDiv (scroll)="checkScrollPosition()">
        <ng-container tabindex="1" *ngFor="let message of messageList;let i = index;" #chatDivElem>
            <div *ngIf="message?.author !== 'system' && message?.showMessage">
                <div class="op-d-flex op-justify-content-start" [ngClass]="{'active': identity === message?.author}"
                    id="{{message?.sid}}" (click)="message?.attributes?.status === 'failed' && retryCreateMessage(message)">
                    <div class="message-cont" [ngClass]="{'active-previous': (identity !== messageList[i-1]?.author && i !== 0),
                    'active-next': (identity !== messageList[i+1]?.author && i+1 !== messageList?.length)}">
                        <div class="message op-d-flex op-flex-column"
                            [ngClass]="{'first-message': (i === messageList.length-1) || message?.participantName !== messageList[i+1]?.participantName}">
                            <span *ngIf="showAuthorName(message, i)" class="author-name">
                                {{message?.isPrivacyMsg ? message?.author : message?.participantName}}</span>
                            <div>
                                <div *ngIf="message?.isPrivacyMsg" [ngClass]="{'op-mb-3': message.body}">
                                    <span class="privacy_msg">{{this.translate.instant('inCall.chat.privacyMsg')}}</span>
                                    <button class="privacy_msg policy" (click)="onPrivacyLinkClick()">{{this.translate.instant('inCall.chat.privacyPolicy')}}</button>
                                </div>
                                <pre class="op-m-0" [innerHTML]="message.body | linky | marked"></pre>
                                <div class="op-d-flex op-justify-content-end op-align-items-center op-flex-wrap"
                                    *ngIf="message?.showCustomOptions">
                                    <ng-container
                                        *ngFor="let btn of message.attributes.custom_message.response_options">
                                        <button class="op-btn op-btn-primary op-btn-round op-mx-1 op-my-2"
                                            id="{{btn.text}}" (click)="executeBtnAction(message, btn, $event)"
                                            [ngClass]="getClass(message?.attributes?.custom_message?.response_value, btn.type)"
                                            *ngIf="showActionButton(message,btn)"
                                            [disabled]="disableButton(message.attributes.custom_message)">{{btn.text}}
                                        </button>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="message?.attributes?.custom_message?.response_value === 'null'">
                                        <span class="canceled-msg op-my-3"
                                            [innerHTML]="'common.cancelled' | translate"></span>
                                    </ng-container>
                                </div>
                                <span class="hidden">
                                    <span>
                                        {{message?.updatedTime}}
                                    </span>
                                    <span class="timestamp">
                                        {{message?.updatedTime}}
                                    </span>
                                </span>
                            </div>
                            <div class="retry" *ngIf="message?.attributes?.status === 'failed'">
                                <span class="retry-message">{{ 'inCall.chat.retryMessage' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="member-update" *ngIf="message?.author === 'system' && message?.attributes?.user_id !== +guestUserId?.split('_')[1]">
                <span *ngIf="message?.attributes?.action === msgType.joined"
                      [innerHTML]="'inCall.chat.joinMessage' | translate : {userName: message?.attributes?.user_name}"></span>
                <span *ngIf="message?.attributes?.action === msgType.left"
                      [innerHTML]="'inCall.chat.leftMessage' | translate : {userName: message?.attributes?.user_name}"></span>
            </div>
        </ng-container>
    </div>
    <div class="chat-window__input" *ngIf="!inRoutingState || textEnabledForRouting">
        <div class="typing-info" *ngIf="currentTypingUsers.length !== 0">
            <ng-container *ngFor="let user of currentTypingUsers; let i = index;">
                <span *ngIf="i<2">{{user?.split(' ')[0]}}<span *ngIf="currentTypingUsers.length > 2 && i === 0"
                        class="op-mr-1">,</span>
                    <span class="op-mx-1" *ngIf="currentTypingUsers.length  === 2 && i === 0"
                        [innerHTML]="'common.and' | translate"></span>
                </span>
            </ng-container>
            <ng-container *ngIf="currentTypingUsers.length > 2">
                <span *ngIf="currentTypingUsers.length === 3" class="op-ml-1"
                    [innerHTML]="'common.andOther' | translate: {count : currentTypingUsers.length - 2}">
                </span>
                <span *ngIf="currentTypingUsers.length > 3" class="op-ml-1"
                    [innerHTML]="'common.andOthers' | translate: {count : currentTypingUsers.length - 2}">
                </span>
            </ng-container>
            <span class="op-ml-1 op-d-flex op-align-items-center">
                <span *ngIf="currentTypingUsers.length === 1" class="op-mr-1"
                    [innerHTML]="'common.is' | translate"></span>
                <span [innerHTML]="'common.typing' | translate"></span>
                <object class="op-ml-1" type="image/svg+xml"
                    data="https://dx9457ojp328v.cloudfront.net/icons/waiting-animated-three-dots.svg"></object>
            </span>
        </div>
        <form [formGroup]="chatForm" class="op-d-flex op-align-items-center">
            <textarea type="text" #messageField formControlName="chatMessage"
                (keyup)="sendTyping(true);autoGrow(messageField);" (focusin)="sendTyping(true)"
                (focusout)="sendTyping(false)" [placeholder]="'inCall.typeMessage' | translate"
                (click)="sendSnowplowEvent()" (keyup.enter)="sendMessageFn(); autoGrow(messageField);"></textarea>
            <button class="send" [disabled]="chatForm?.controls?.chatMessage.invalid"
                [ngClass]="{'disabled': chatForm?.controls?.chatMessage.invalid}"
                (click)="sendMessageFn(); autoGrow(messageField);">
                <svg-icon name="paper-plane"></svg-icon>
            </button>
        </form>
    </div>
</div>

<div *ngIf="isOutboundCall" class="mobile-outbound op-mt-1">
    <ng-container *ngFor="let message of messageList;let i = index;">
        <div class="message"
            [ngClass]="{'fade-out': i < (messageList?.length - 1), 'latest': i === (messageList?.length - 1)}"
            *ngIf="i >= (messageList?.length - 2) && message?.author !== 'system'">
            <pre class="op-m-0" [innerHTML]="message.body | marked" *ngIf="!isFirstInitiated"></pre>
            <span *ngIf="isFirstInitiated && i === 0">
                <object type="image/svg+xml"
                    data="https://dx9457ojp328v.cloudfront.net/icons/waiting-animated-three-dots.svg"></object>
            </span>
        </div>
    </ng-container>
</div>
