import { Component, OnInit } from '@angular/core';
import { PluginStates, RoutesUrls } from '../../core/constants/common.enum';
import { UtilsService } from '../../core/services/utils/utils.service';
import { SnowplowService } from '../../core/services/snowplow/snowplow.service';
import {
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels, SnowplowTrackerProperties, SnowplowTrackerSchemas
} from '../../core/constants/trackerLabels';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scheduled-calls-info-screen',
  templateUrl: './scheduled-calls-info-screen.component.html',
  styleUrls: ['./scheduled-calls-info-screen.component.scss']
})
export class ScheduledCallsInfoScreenComponent implements OnInit {
  daysInfoEn = [{
    day: 'monday',
    value: 'COFFEE BREAK'
  },{
    day: 'tuesday',
    value: '10:00 AM - 3:00 PM ET'
  },{
    day: 'wednesday',
    value: '10:00 AM - 3:00 PM ET'
  },{
    day: 'thursday',
    value: '10:00 AM - 7:00 PM ET'
  },{
    day: 'friday',
    value: '10:00 AM - 7:00 PM ET'
  },{
    day: 'saturday',
    value: '10:00 AM - 7:00 PM ET'
  },{
      day: 'sunday',
      value: '10:00 AM - 7:00 PM ET'
  }];
  daysInfoFr = [{
    day: 'monday',
    value: 'PAUSE CAFÉ'
  },{
    day: 'tuesday',
    value: '10h00 - 15h00 ET'
  },{
    day: 'wednesday',
    value: '10h00 - 15h00 ET'
  },{
    day: 'thursday',
    value: '10h00 - 19h00 ET'
  },{
    day: 'friday',
    value: '10h00 - 19h00 ET'
  },{
    day: 'saturday',
    value: '10h00 - 19h00 ET'
  },{
    day: 'sunday',
    value: '10h00 - 19h00 ET'
  }]
  daysInfo: any = [];
  currentLanguage: string;
  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties,
    schemas: SnowplowTrackerSchemas
  };

  constructor(private utils: UtilsService,
              private snowplowService: SnowplowService,
              private router: Router) {
    this.currentLanguage = this.utils.getLocalVal(PluginStates.language);
    this.daysInfo = this.currentLanguage?.includes('fr') ? this.daysInfoFr : this.daysInfoEn;
  }

  ngOnInit(): void {
    this.snowplowService.trackStructEvent(this.spTracker.labels.booking, this.spTracker.labels.screenShown,
         this.spTracker.labels.genericMsg, 'version', 1);
  }

  contactUs(){
    if(this.currentLanguage?.includes('fr')){
      window.open('https://www.delonghi.com/fr-ca/contact-us','_blank');
      return;
    }
    window.open('https://www.delonghi.com/en-ca/contact-us ','_blank');
  }

  close(){
    this.snowplowService.trackStructEvent(this.spTracker.labels.booking, this.spTracker.labels.close,
        this.spTracker.labels.genericMsg, 'version', 1);
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }]);
  }

}
