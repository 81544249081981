import { Inject, Injectable } from '@angular/core';
import { enableLinkClickTracking } from '@snowplow/browser-plugin-link-click-tracking';
import {
  enableActivityTracking,
  newTracker,
  trackPageView,
  trackStructEvent,
  updatePageActivity
} from '@snowplow/browser-tracker';
import { environment } from '../../../../environments/environment';
import { Constants } from '../../constants/constant';
import { Context } from '../../constants/common.enum';
import { addTrans, EcommercePlugin, trackTrans } from '@snowplow/browser-plugin-ecommerce';

@Injectable({
  providedIn: 'root'
})
export class SnowplowService {
  licenseKey: string;

  constructor(@Inject(Constants.licenseKey) licenseKey: string) {
    this.licenseKey = licenseKey.replace(/ /g,'');
  }

  public initializeSnowplow(): void {
    const envName = environment.environmentName;
    let collectorEndpoint = environment.snowplowCollectorEndpoint;
    if (environment.local) {
      collectorEndpoint = 'sp.demos.snowcatcloud.com';
    }

    newTracker('tracker_id', collectorEndpoint, {
      appId: `plugin-${envName}-${this.licenseKey}`,
      cookieSameSite: 'Lax',
      plugins: [EcommercePlugin()],
    });

    enableActivityTracking({
      heartbeatDelay: 10,
      minimumVisitLength: 10
    });

    trackPageView();

    enableLinkClickTracking({
      options: {},
      pseudoClicks: true,
      trackContent: true,
    });
  }

  /**
   *
   * @param category required, The name you supply for the group of objects you want to track e.g. ‘media’, ‘ecomm’
   * @param action required, A string which defines the type of user interaction for the web object e.g. ‘play-video’, ‘add-to-basket’
   * @param label optional, An optional string which identifies the specific object being actioned e.g. ID of the video being played,
   * or the SKU or the product added-to-basket
   * @param property optional, An optional string describing the object or the action performed on it.
   * This might be the quantity of an item added to basket
   * @param value optional, An optional float to quantify or further describe the user action.
   * This might be the price of an item added-to-basket, or the starting time of the video where play was just pressed
   * @param context optional, An optional item using for advance tracking
   */
  public trackStructEvent(category: string, action: string, label?: string, property?: string, value?: number | any, context?: Context[]): void {
    context = context?.length ? context?.filter(element => element !== undefined || Object.keys(element)?.length !== 0) : [];
    if(context?.length){
      trackStructEvent({ category, action, label, property, value, context });
    } else {
      trackStructEvent({ category, action, label, property, value });
    }
  }

  public trackTransactions(orderId: string, total: number, tax: number, shipping: number, currency: string, context?: Context[]) {
    addTrans({
      orderId,
      total,
      tax,
      shipping,
      currency,
      context
    });
    trackTrans();
  }


  public getSnowplowUserId(): string {
    const idBreakdown = this.getSnowplowIdFromCookie();
    let domainUser;
    if (idBreakdown) {
      domainUser = idBreakdown[0];
    }
    return domainUser || '';
  }

  public getSnowplowSessionId(): string {
    const idBreakdown = this.getSnowplowIdFromCookie();
    let domainUser;
    if (idBreakdown) {
      domainUser = idBreakdown[5];
    }
    return domainUser || '';
  }

  private getSnowplowIdFromCookie(): string[] {
    const snowplowCookies = document.cookie?.split(';').filter((x) => x.includes('_sp'));
    const snowplowIdCookie = snowplowCookies?.find((x) => x.includes('_sp_id')) || '';
    const idCookie = snowplowIdCookie?.trim().split('=');
    return idCookie[1]?.split('.');
  }
}
