<div class="scheduled-calls-info-container">
    <h3 [innerHTML]="'scheduledCallsInfo.welcomeToDelonghi' | translate"></h3>
    <div [innerHTML]="'scheduledCallsInfo.rightPlace' | translate" class="demo-info"></div>
    <div [innerHTML]="'scheduledCallsInfo.demoInfo' | translate"></div>
    <div [innerHTML]="'scheduledCallsInfo.timeSlotsInfo' | translate"></div>
    <div class="hours-info">
        <div *ngFor="let name of daysInfo" class="op-d-flex op-justify-content-between op-mb-1">
            <span [innerHTML]="'scheduledCallsInfo.days.'+ name.day | translate"></span>
            <span class="time">{{name?.value}}</span>
        </div>
        <div [innerHTML]="'scheduledCallsInfo.note' | translate" class="note"></div>
    </div>
    <div class="help">
        <span [innerHTML]="'scheduledCallsInfo.help' | translate"></span>
        <span [innerHTML]="'scheduledCallsInfo.contactUs' | translate" class="contact" (click)="contactUs()"></span>
    </div>
    <div class="footer op-d-flex op-justify-content-end">
        <button [innerHTML]="'common.close' | translate" (click)="close()"></button>
    </div>
</div>
